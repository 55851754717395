import { Dropdown } from 'antd'
import { useState } from 'react'
import Sub from './Sidebar'
import styles from '../styles/SubLayout.module.scss'
import { useHistory } from 'react-router-dom'
import { Drawer } from './Drawer'

type SubLayoutProps = {
  children: any,
  menu?: any,
  title: string,
  content?: any,
  mobileSearch?: any
}

const SubLayout = ({ children, title, menu, content, mobileSearch }: SubLayoutProps) => {

  const [visible, setVisible] = useState(false)

  const closeDrawer = () => {
    setVisible(false)
  }

  const openDrawer = () => {
    setVisible(true)
  }


  const { goBack } = useHistory()

  return (
    <div className={styles.subLayout}>
      <div className="header">
        <button
          onClick={() => {
            goBack()
          }}
          className='btn btn-link btn-icon'>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
          </svg>
          Back
        </button>
      </div>
      <div className={styles.headerMobile}>
        <div onClick={openDrawer} className={styles.left}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </div>
        <div className={styles.center}>
          <h2>{title}</h2>
        </div>
        <div className={styles.right}>
          {
            menu &&
            <Dropdown className="page-controls-row-more" overlay={menu} trigger={['click']}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z" />
              </svg>
            </Dropdown>
          }
        </div>
      </div>
      {
        mobileSearch &&
        <div className={styles.mobileSearch}>
          {mobileSearch}
        </div>
      }
      <div className={styles.headerWeb}>
        {content}
      </div>
      <div className={styles.content}>
        {
          children
        }
      </div>
      <Drawer
        visible={visible}
        onClose={closeDrawer}
      >
        <Sub />
      </Drawer>
      <div className={styles.responsive}>
        <Sub />
      </div>
    </div>
  )
}

export default SubLayout
