import { Route, Switch, Redirect } from 'react-router-dom';
import Layout from '../components/Layout'
import { FC, useContext } from 'react';
import { routes } from '../helpers/routes';
import { AuthContext } from '../context/AuthProvider';

const ProtectedRoutes: FC = () => {

  const { user } = useContext(AuthContext)

  const userAccess = user.access.map(view => view.view)

  const filteredRoutes = user.role.value === 'admin' ? routes : routes.filter(route => userAccess.includes(route.generalPath))

  return (
    <Switch>
      {filteredRoutes.map(({ component: Component, path, exact, title }) => (
        <Route
          path={path}
          key={path}
          exact={exact}
        >
          <Layout title={title}>
            <Component />
          </Layout>
        </Route>
      ))}
      <Redirect to='/404' />
    </Switch>
  )
};

export default ProtectedRoutes;