import React from 'react'
import { Link } from 'react-router-dom'

const Requests = () => {
  return (
    <div>
      <div className="header">
        <Link to='/' className='btn btn-link btn-icon'>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
          </svg>
          Back to Home
        </Link>
      </div>
      <div className="empty">
        <div className="icon">
          <span className="material-symbols-rounded">
            person_add
          </span>
        </div>
        <h3>There are no requests yet</h3>

      </div>
    </div>
  )
}

export default Requests
