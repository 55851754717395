import { api } from '../../api/api'
import { DiscrepancyElement } from '../../interfaces/Discrepancy'

export const getDiscrepancies = async (aircraftId: string) => {
  const { data } = await api.get<any>(`/api/discrepancies/aircraft/${aircraftId}`)

  return data
}

export const postDiscrepancy = async (discrepancy: DiscrepancyElement) => {
  await api.post('/api/discrepancies', discrepancy)
}