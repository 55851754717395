import { api } from '../../../api/api'
import { useEffect, useRef, useState } from 'react'
import { AircraftElement } from '../../../interfaces/Aircraft'
import { User } from '../../../interfaces/User'
import styles from '../../../styles/Aircraft.module.scss'
import Modal from '../../../components/Modal'
import toast from 'react-hot-toast'
import FleetGrid from '../../../components/FleetGrid'
import Select from '../../../components/Select'
import Input from '../../../components/Input'
import PermissionWrapper from '../../../components/PermissionWrapper'
import { useForm } from 'react-hook-form'
import { getFleet } from '../../../services/aircraft/getFleet'
import { getUsers } from '../../../services/users/getUsers'
import { postAircraft } from '../../../services/aircraft/postAircraft'
import { Link } from 'react-router-dom'
import Loading from '../../../components/Loading'

const Aircraft = () => {

  const [visible, setVisible] = useState(false)

  const [loading, setLoading] = useState(true)

  const [formItems, setFormItems] = useState<{
    itemId: string;
    itemName: string;
  }[]>([])

  const [name, setName] = useState('')

  const [image, setImage] = useState('')

  const [companyLogo, setCompanyLogo] = useState('')

  const imageRef = useRef<any>()

  const logoRef = useRef<any>()

  const { control, register, handleSubmit } = useForm()

  const [aircraft, setAircraft] = useState<AircraftElement[]>([])

  const [users, setUsers] = useState<User[]>([])

  const fetchFleet = async () => {
    const data = await getFleet('fbo')
    setAircraft(data.aircraft)
    setLoading(false)
  }

  const fetchUsers = async () => {
    const data = await getUsers()
    setUsers(data.users)
  }

  useEffect(() => {
    fetchFleet()
    fetchUsers()
  }, [])

  const openModal = () => {
    setVisible(true)
  }

  const closeModal = () => {
    setVisible(false)
  }

  const addAircraft = async (values: any) => {

    let total = 0;
    let cycles = 0
    const status = formItems.map((item: any) => {
      if (`${item.itemId}-hours` in values) {
        total = +values[`${item.itemId}-hours`]
      }
      if (`${item.itemId}-cycles` in values) {
        cycles = +values[`${item.itemId}-cycles`]
      }

      return {
        ...item,
        total,
        cycles,
      }
    })

    const postedAircraft = {
      ...values,
      analyst: values.analyst.value,
      operator: values.operator.value,
      image,
      companyLogo,
      status,
      app: {
        label: 'FBO',
        value: 'fbo'
      },
      active: true
    }

    try {
      await postAircraft(postedAircraft)
      fetchFleet()
      setVisible(false)
      toast.success('Record added successfully')
    } catch (error: any) {
      toast.error(error.response.data.message)
    }


  }

  const saveToS3 = async (e: any, imageType: string) => {
    const file = e.target.files[0]
    if (!file) {
      return
    }
    const formData = new FormData()
    formData.append('image', file)

    try {
      const { data } = await api.post('/api/aircraft/upload', formData)

      if (imageType === 'image') {
        setImage(data.Location)
      } else {
        setCompanyLogo(data.Location)
      }
      toast.success('Uploaded image')
    } catch (error: any) {
      toast.error(error.response.data.message)
    }

  }

  if (loading) return <Loading />

  return (
    <>
      {
        aircraft.length === 0 ?
          <div className="empty">
            <div className="icon">
              <span className="material-symbols-rounded">
                airlines
              </span>
            </div>
            <h3>There are no aircraft yet</h3>
            <PermissionWrapper
              role='create-edit'
              view='/fbo/fleet'
            >
              <button onClick={openModal} className='btn btn-blue'>New aircraft</button>
            </PermissionWrapper>
          </div>
          :
          <>
            <div className="header">
              <Link to='/fbo' className='btn btn-link btn-icon'>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                </svg>
                Back to FBO
              </Link>
            </div>
            <div className={styles.header}>
              <h2>{`FBO > Fleet`}</h2>
              <PermissionWrapper
                role='create-edit'
                view='/fbo/fleet'
              >
                <button onClick={openModal} className='btn btn-blue'>New aircraft</button>
              </PermissionWrapper>
            </div>
            <FleetGrid aircraft={aircraft} />
          </>
      }
      <PermissionWrapper
        role='create-edit'
        view='/fbo/fleet'
      >
        <Modal
          visible={visible}
          title="New aircraft"
          onCancel={closeModal}
          onClose={closeModal}
          onOk={handleSubmit(addAircraft)}
        >
          <form
          >
            <Input
              name='serialNumber'
              label='Serial number'
              placeholder='Serial number'
              required
              register={register}
            />
            <Input
              name='registrationNumber'
              label="Registration number"
              placeholder='Registration number'
              required
              register={register}
            />
            <Input
              name='model'
              label="Model"
              placeholder='Model'
              required
              register={register}
            />
            <Select
              name='operator'
              label='Operator'
              required
              control={control}
              options={users?.map(user => (
                {
                  label: user.name,
                  value: user.id
                }
              ))}
            />
            <Select
              name='analyst'
              label='Analyst'
              required
              control={control}
              options={users?.map(user => (
                {
                  label: user.name,
                  value: user.id
                }
              ))}
            />
            <div className='input-group'>
              <label>Aircraft image</label>
              <input
                onChange={(e) => {
                  saveToS3(e, 'image')
                }}
                ref={imageRef}
                style={{ display: 'none' }}
                type='file'
                accept='image/*'
              />
              <>
                {
                  image !== '' &&
                  <div
                    className='preview'>
                    <img src={image} alt='' />
                    <div
                      onClick={() => {
                        setImage('')
                      }}
                      className="delete">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                      </svg>
                    </div>
                  </div>
                }
                {
                  image === '' &&
                  <div
                    className='dropzone'
                    onClick={() => { imageRef.current.click() }} >
                    <span>
                      Choose image
                    </span>
                  </div>
                }
              </>
            </div>
            <div className='input-group'>
              <label>Company logo </label>
              <input
                onChange={(e) => {
                  saveToS3(e, 'companyLogo')
                }}
                ref={logoRef}
                style={{ display: 'none' }}
                type='file'
                accept='image/*'
              />
              <>
                {
                  companyLogo !== '' &&
                  <div
                    className='preview'>
                    <img src={companyLogo} alt='' />
                    <div
                      onClick={() => {
                        setCompanyLogo('')
                      }}
                      className="delete">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                      </svg>
                    </div>
                  </div>
                }
                {
                  companyLogo === '' &&
                  <div
                    className='dropzone'
                    onClick={() => { logoRef.current.click() }} >
                    <span>
                      Choose image
                    </span>
                  </div>
                }
              </>
            </div>
            <h3>Aircraft status</h3>
            <div className='input-group'>
              <label htmlFor="">Tracking rate</label>
              <input
                placeholder='Tracking rate'
                value={name}
                onChange={(e) => {
                  setName(e.target.value)
                }}
                className='input'
                type="text"
                style={{
                  marginBottom: 20
                }}
              />
              <button
                onClick={() => {
                  if (name.trim().length === 0) {
                    toast.error(`Field can't be empty`)
                    setName('')
                  } else {
                    const item = {
                      itemName: name.trim(),
                      itemId: name.trim().toLowerCase()
                    }
                    if (formItems.filter((item) => item.itemName === name).length > 0) {
                      toast.error('Already added')
                    } else {
                      setFormItems([...formItems, item])
                      setName('')
                    }
                  }
                }}
                className='btn btn-blue' type='button'>Add</button>

            </div>
            <>
              {
                formItems.length > 0 &&
                <>
                  <h4>Added items</h4>
                  <div
                    className={styles.formItems}
                  >

                    {
                      formItems.map((formItem) => (
                        <div
                          className={styles.itemName}
                          key={formItem.itemName}>
                          <h4>{formItem.itemName}</h4>
                          <div

                            onClick={() => {

                              const filtered = formItems.filter(item => item.itemId !== formItem.itemId)
                              setFormItems(filtered)
                            }}

                            className={styles.close}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </div>
                          <Input
                            register={register}
                            type='number'
                            name={`${formItem.itemId}-hours`}
                            label=''
                            placeholder={`${formItem.itemName} hours`}
                          />
                          <Input
                            register={register}
                            type='number'
                            name={`${formItem.itemId}-cycles`}
                            label=''
                            placeholder={`${formItem.itemName} cycles`}
                          />
                        </div>
                      ))
                    }
                  </div>
                </>
              }
            </>
          </form>
        </Modal>
      </PermissionWrapper>
    </>
  )
}

export default Aircraft
