import React, { FC } from 'react'
import { AircraftElement } from '../interfaces/Aircraft'
import AircraftItem from './AircraftItem'
import styles from '../styles/FleetGrid.module.scss'

interface Props{
  aircraft: AircraftElement[]
}

const FleetGrid: FC<Props> = ({ aircraft }) => {
  return (
    <div className={styles.aircraftGrid}>
      {
        aircraft.map(item => (
          <AircraftItem
            key={item.id}
            {...item}
          />
        ))
      }
    </div>
  )
}

export default FleetGrid