import { Tag, Menu } from 'antd'
import { api } from '../api/api'
import moment from 'moment'
import { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import SubLayout from '../components/SubLayout'
import { AircraftContext } from '../context/AircraftProvider'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { reportColumns } from '../helpers/reportColumns'
import { Report } from '../components/Report'
import PermissionWrapper from '../components/PermissionWrapper'
import Modal from '../components/Modal'
import Input from '../components/Input'
import { useForm } from 'react-hook-form'
import TextArea from '../components/TextArea'
import Select from '../components/Select'
import toast from 'react-hot-toast'
import Table, { Column } from '../components/Table'
import Loading from '../components/Loading'
import { getBulletins, postBulletin } from '../services/service-bulletins'
import { ServiceBulletin } from '../interfaces/ServiceBulletin'
import DatePicker from "../components/DatePicker"

const ServiceBulletins = () => {

  const { aircraftElement, setAircraftElement } = useContext(AircraftContext)

  const { control, register, reset, handleSubmit, formState: { errors } } = useForm()

  const { aircraftId } = useParams<any>()

  const [bulletins, setBulletins] = useState<ServiceBulletin[]>([])

  const [tempBulletins, setTempBulletins] = useState<ServiceBulletin[]>([])

  const [visible, setVisible] = useState(false)

  const [loading, setLoading] = useState(true)

  const [departureDate, setDepartureDate] = useState(new Date())

  const [intervalType, setIntervalType] = useState<any>(null)
  const [doc, setDoc] = useState(false)

  useEffect(() => {
    if (!aircraftElement) {
      api.get(`/api/aircraft/${aircraftId}`).then(({ data }) => {
        setAircraftElement(data.aircraft)

      }).catch(error => console.log(error))
    }

  }, [aircraftId, setAircraftElement, aircraftElement])

  const fetchBulletins = useCallback(async () => {
    try {
      const data = await getBulletins(aircraftId)
      setBulletins(data.bulletins)
      setTempBulletins(data.bulletins)
      setLoading(false)
    } catch (error: any) {
      toast.error(error.response.data.message)
      setLoading(false)
    }
  }, [aircraftId])

  useEffect(() => {
    fetchBulletins()
  }, [fetchBulletins])

  const createBulletin = async (values: any) => {
    const bulletin = {
      ...values,
      aircraft: aircraftId,
      appliesTo: values.appliesTo.value,
      intervalType: values.intervalType.value,
      disposition: values.disposition.value
    }
    try {
      await postBulletin(bulletin)
      toast.success('Bulletin created')
      fetchBulletins()
      setVisible(false)
      reset()
    } catch (error: any) {
      toast.error(error.response.data.message)
    }
  }

  const search = (e: ChangeEvent<HTMLInputElement>) => {
    setBulletins(tempBulletins.filter(task => task.description.toLowerCase().includes(e.target.value.toLowerCase())))
  }

  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => {
          setVisible(true)
        }}
        key='New bulletin'>
        New bulletin
      </Menu.Item>
      {
        doc ?
          <PDFDownloadLink
            document={<Report
              logo={aircraftElement.companyLogo}
              columns={reportColumns}
              aircraft={aircraftElement}
              title='Status report'
              data={bulletins}
            />
            }
            fileName={`Status_report.pdf`}>
            {({ blob, url, loading, error }) =>
              loading ? 'Loading document...' : <Menu.Item >Download report</Menu.Item>
            }
          </PDFDownloadLink>
          :

          <Menu.Item
            onClick={() => { setDoc(true) }}
          >
            Generate report
          </Menu.Item>

      }
    </Menu>
  );

  const content = (
    <div
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <div style={{
        marginRight: 10,
        flex: 1
      }}>
        <input
          className='input'
          onChange={search}
          placeholder='Search bulletins' />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <PermissionWrapper
          role='create-edit'
          view='/mtx/service-bulletins'
        >
          <button
            className='btn btn-blue'
            style={{ marginRight: 10 }}
            onClick={() => {
              setVisible(true)
            }}
          >New bulletin</button>
        </PermissionWrapper>
        <div>
          {
            doc ? <div className="download-btn-wrapper">

              <PDFDownloadLink
                document={<Report
                  logo={aircraftElement.companyLogo}
                  columns={reportColumns}
                  aircraft={aircraftElement}
                  title='Status report'
                  data={bulletins}
                />
                }
                fileName={`Status_report.pdf`}>
                {({ blob, url, loading, error }) =>
                  loading ? 'Loading document...' : <button className='btn btn-blue'>Download report</button>
                }
              </PDFDownloadLink>

            </div> :

              <button
                className='btn btn-blue'
                onClick={() => { setDoc(true) }}
              >
                Generate report
              </button>

          }
        </div>
      </div>
    </div>
  )

  const mobileSearch = (
    <input
      className='input'
      onChange={search}
      placeholder='Search bulletins'
    />
  )

  const columns: Column[] = [
    {
      title: 'ATA',
      dataIndex: 'ata',
      key: 'ata',
    },
    {
      title: 'Bulletin description',
      dataIndex: 'description',
      key: 'description',
      width: 100
    },
    {
      title: 'P/N - S/N',
      dataIndex: 'partNumberSerialNumber',
      key: 'partNumberSerialNumber',
      render: (text: string, record: ServiceBulletin) => {
        return (
          <>
            {
              record?.partNumber && <span className='block mb-10'>P/N: {record?.partNumber.toString().replace("P/N", "")}</span>
            }

            {
              record?.serialNumber && <span className='block mb-10'>S/N: {record?.serialNumber.toString().replace("S/N", "")}</span>
            }
          </>
        )
      }
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
    },
    {
      title: 'Interval',
      dataIndex: 'interval',
      key: 'interval',
      render: (text: string, record: ServiceBulletin) => {
        return (
          <>
            {
              record.intervalType === 'hour' ?
                <span>{record.hourInterval} hours</span>
                :
                record.intervalType === 'date' ?
                  <span>{record.dateInterval} days</span>
                  :
                  record.intervalType === 'oc' ?
                    <span>O/C</span>
                    :
                    record.intervalType === 'cycle' ?
                      <span>{record.cycleInterval} cycles</span>
                      :
                      record.intervalType === 'both' ?
                        <>
                          <span className='block mb-10'>{record.dateInterval} days</span>
                          <span className='block mb-10'>{record.hourInterval?.toFixed(2)} hours</span>
                        </>
                        :
                        <span></span>
            }
          </>
        )
      }
    },
    {
      title: 'C/W',
      dataIndex: 'cw',
      key: 'cw',
      render: (text: string, record: ServiceBulletin) => <>
        {
          record.intervalType === 'hour' ?
            <>{record.cwHour}</>
            :
            record.intervalType === 'date' ?
              <>{moment(record.cwDate).format('ll')}</>
              :
              record.intervalType === 'oc' ?
                (record.cwDate ? <span>{moment(record.cwDate).format('ll')}</span> : 'N/A')
                :
                record.intervalType === 'cycle' ?
                  <span>{record.cwCycle}</span>
                  :
                  record.intervalType === 'both' ?
                    <>
                      <span className='block mb-10'>{moment(record.cwDate).format('ll')}</span>
                      <span className='block mb-10'>{record.cwHour?.toFixed(2)}</span>
                    </>
                    :
                    <span></span>

        }
      </>
    },
    {
      title: 'Due',
      dataIndex: 'due',
      key: 'due',
      render: (text: string, record: ServiceBulletin) => <>
        {
          record.intervalType === 'hour' ?
            <>{record.dueHour}</>
            :
            record.intervalType === 'date' ?
              <>{moment(record.dueDate).format('ll')}</>
              :
              record.intervalType === 'oc' ?
                <span>N/A</span>
                :
                record.intervalType === 'cycle' ?
                  <span>{record.dueCycle}</span>
                  :
                  record.intervalType === 'both' ?
                    <>
                      <span className='block mb-10'>{moment(record.dueDate).format('ll')}</span>
                      <span className='block mb-10'>{record.dueHour?.toFixed(2)}</span>
                    </>
                    :
                    <span></span>
        }
      </>,
    },
    {
      title: 'Remaining',
      dataIndex: 'remaining',
      key: 'remaining',
      render: (text: string, record: ServiceBulletin) => {
        return (
          <>
            {
              record.intervalType === 'hour' ?
                <span>{record.remaining && record.remaining?.toFixed(2)} hours</span>
                :
                record.intervalType === 'date' ?
                  <span>{record.remaining} days</span>
                  :
                  record.intervalType === 'oc' ?
                    <span>N/A</span>
                    :
                    record.intervalType === 'cycle' ?
                      <span>{record.remaining} cycles</span>
                      :
                      record.intervalType === 'both' ?
                        <>
                          <span className='block mb-10'>{record.remaining.days} days</span>
                          <span className='block mb-10'>{record.remaining.hours?.toFixed(2)} hours</span>
                        </>
                        :
                        <span></span>
            }
          </>
        )
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text: string, record: ServiceBulletin) => <Tag color={record.status === 'OK' ? 'green' : 'red'} >{text}</Tag>
    },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      render: (text: string, record: ServiceBulletin) => <Link className='btn btn-blue' to={`/mtx/aircraft/${aircraftId}/service-bulletins/${record.id}`}>View</Link>
    },
  ]

  return (
    <>
      <SubLayout
        mobileSearch={mobileSearch}
        content={content}
        title='Service Bulletins'
        menu={menu}>
        {
          loading && <Loading />
        }
        {
          !loading &&
          <div className='tasks'>
            <Table
              data={bulletins}
              columns={columns}
            />
          </div>
        }
      </SubLayout>
      <Modal
        visible={visible}
        title="New bulletin"
        onCancel={() => {
          setVisible(false)
          reset()
        }}
        onClose={() => {
          setVisible(false)
          reset()
        }}
        onOk={handleSubmit(createBulletin)}
      >
        <div
        >
          <Input
            name='partNumber'
            label='Part Number'
            placeholder='Part Number'
            errors={errors}
            register={register}
          />
          <Input
            name='serialNumber'
            label='Serial number'
            placeholder='Serial number'
            errors={errors}
            register={register}
          />
          <TextArea
            name='description'
            label='Description'
            placeholder='Description'
            required
            errors={errors}
            register={register}
          />
          <TextArea
            name='notes'
            label='Notes'
            placeholder='Notes'
            register={register}
          />
          <Select
            name='intervalType'
            label='Interval type'
            required
            errors={errors}
            control={control}
            options={[
              {
                label: 'O/C',
                value: 'oc'
              },
              {
                label: 'Hour',
                value: 'hour'
              },
              {
                label: 'Date',
                value: 'date'
              },
              {
                label: 'Cycle',
                value: 'cycle'
              },
              {
                label: 'Both',
                value: 'both'
              },
            ]}
            handleChange={(e: any) => {
              setIntervalType(e.value);
            }}
          />
          {
            (intervalType === 'hour' || intervalType === 'both') &&
            <Input
              name='hourInterval'
              label='Hour Interval'
              placeholder='Hour Interval'
              required
              errors={errors}
              register={register}
            />
          }
          {
            (intervalType === 'date' || intervalType === 'both') &&
            <Input
              name='dateInterval'
              label='Date Interval'
              placeholder='Date Interval'
              required
              errors={errors}
              register={register}
            />
          }
          {
            intervalType === 'cycle' &&
            <Input
              name='cycleInterval'
              label='Cycle Interval'
              placeholder='Cycle Interval'
              required
              errors={errors}
              register={register}
            />
          }
          <Input
            name='ata'
            label='ATA'
            placeholder='ATA'
            required
            errors={errors}
            register={register}
          />
          {
            (intervalType === 'date' || intervalType === 'both' || intervalType === 'oc') &&
            <DatePicker
              name='cwDate'
              label='CW Date'
              required
              selectedDate={departureDate}
              setSelectedDate={setDepartureDate}
              control={control}
              minDate={undefined}
            />
          }
          {
            (intervalType === 'hour' || intervalType === 'both' || intervalType === 'oc') &&
            <Input
              name='cwHour'
              label='CW Hour'
              placeholder='CW Hour'
              required
              errors={errors}
              register={register}
            />
          }
          {
            intervalType === 'cycle' &&
            <Input
              name='cwCycle'
              label='CW Cycle'
              placeholder='CW Cycle'
              required
              errors={errors}
              register={register}
            />
          }
          <Select
            name='appliesTo'
            label='Applies To'
            required
            errors={errors}
            control={control}
            options={
              aircraftElement.status?.map((item: any) =>
              ({
                label: item.itemName,
                value: item.itemId
              })
              )
            }
          />
          <DatePicker
              name='effectiveDate'
              label='Eff. Date'
              required
              selectedDate={departureDate}
              setSelectedDate={setDepartureDate}
              control={control}
              minDate={undefined}
            />
          <Select
            name='disposition'
            label='Disposition'
            required
            errors={errors}
            control={control}
            options={
              [
                {
                  label: 'Mandatory',
                  value: 'mandatory'
                },
                {
                  label: 'Recommended',
                  value: 'recommended'
                },
                {
                  label: 'Optional',
                  value: 'optional'
                },
              ]
            }
          />
        </div>
      </Modal>
    </>
  )
}

export default ServiceBulletins
