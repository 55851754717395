import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import styles from '../styles/Card.module.scss'

interface Props {
  path: string,
  icon: JSX.Element,
  name: string,
  description?: string
}

const Card: FC<Props> = ({ path, icon, name, description }) => {
  return (
    <Link
      to={path}
      className={styles.item}>
      <div className={styles.icon}>
        {icon}
      </div>
      <div className={styles.content}>
        <h4>{name}</h4>
        <span>{description}</span>
      </div>
    </Link>
  )
}

export default Card