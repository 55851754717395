import { api } from '../../api/api'
import { ServiceBulletin } from '../../interfaces/ServiceBulletin'

export const getBulletins = async (aircraftId: string) => {
  const { data } = await api.get<any>(`/api/service-bulletins/aircraft/${aircraftId}`)

  return data
}

export const postBulletin = async (bulletin: ServiceBulletin) => {
  await api.post('/api/service-bulletins', bulletin)
}
