import { Document, Font, Image, Page, Text, View } from "@react-pdf/renderer";
import React from 'react'
import moment from 'moment'
import { AircraftElement } from "../interfaces/Aircraft";
import { styles } from "../helpers/styles";

interface ReportProps {
  aircraft: AircraftElement,
  data: any[],
  columns: any[],
  title: string,
  logo: string
}

export const Report = ({ data, columns, aircraft, title, logo = '/global-tracking-logo.png' }: ReportProps) => {
  const Table = ({ data, columns }: any) => {
    return (
      <View style={{ display: 'flex' }}>
        <View style={[styles.row]} fixed>
          {
            columns.map((col: any, index: number) => (
              index === 1 ?
                <View key={col.title} style={[styles.col, { flexGrow: 2 }]}>
                  <Text style={styles.fontSmallBold}>{col.title}</Text>
                </View> :
                <View key={col.title} style={styles.col}>
                  <Text style={styles.fontSmallBold}>{col.title}</Text>
                </View>
            ))
          }

        </View>
        <View >
          {
            data.map((item: any, index: number) => {
              return (
                <View key={index} style={[styles.row]}>
                  {
                    columns.map((col: any, index: number) => {
                      return (

                        index === 1 ?
                          <View key={index} style={[styles.col, { flexGrow: 2 }]}>
                            {
                              ('render' in col) ?
                                <View>
                                  {col.render(item[col.dataIndex], item)}
                                </View> :
                                <Text style={styles.fontSmall}>{item[col.dataIndex]}</Text>
                            }
                          </View> :
                          <View key={index} style={styles.col}>
                            {
                              ('render' in col) ?
                                <View>
                                  {col.render(item[col.dataIndex], item)}
                                </View> :
                                <Text style={styles.fontSmall}>{item[col.dataIndex]}</Text>
                            }
                          </View>
                      )
                    })
                  }
                </View>
              )
            }

            )
          }
        </View>
      </View>
    )
  }

  const infoCols = [
    {
      title: 'Report Date: ',
      value: moment().format('lll')
    },
    {
      title: 'Model: ',
      value: aircraft.model
    },
    {
      title: 'Serial number: ',
      value: aircraft.serialNumber
    },
    {
      title: 'Registration number: ',
      value: aircraft.registrationNumber
    },
    {
      title: 'Analyst: ',
      value: aircraft.analyst.name
    },
    {
      title: 'Operator: ',
      value: aircraft.operator.name
    }
  ]

  return (
    <Document>
      <Page style={styles.body}>
        <View fixed style={styles.header}>
          {/* <View>
            <Image
              source={{
                uri: logo,
                method: "GET",
                headers: { "Cache-Control": "no-cache" }, body: ""
              }}

              style={styles.image}
            />
          </View> */}
          <View>
            <Text style={styles.title}>{title}</Text>
          </View>
          <View>
            <Text style={styles.title}>Global Tracking</Text>
          </View>

        </View>
        <View style={[
          styles.row, { marginBottom: 20, marginTop: 10 }
        ]} fixed>
          <View style={styles.col2}>
            {
              infoCols.map(col => (
                <View key={col.title} style={{ display: 'flex', flexDirection: 'row', marginBottom: 5 }}>
                  <Text style={[styles.fontSmallBold, { marginRight: 5 }]}>{col.title}</Text>
                  <Text style={styles.fontSmall}>{col.value}</Text>
                </View>
              ))
            }
          </View>

          <View style={styles.col2}>
            <View style={[styles.row]}>
              <View style={styles.col}>
                <Text style={styles.fontSmallBold}>Tracking Rate</Text>
              </View>
              <View style={styles.col}>
                <Text style={styles.fontSmallBold}>Hours</Text>
              </View>
              <View style={styles.col}>
                <Text style={styles.fontSmallBold}>Events/Landings</Text>
              </View>
              <View style={styles.col}>
                <Text style={styles.fontSmallBold}>Last Actual Date</Text>
              </View>
            </View>
            {
              aircraft.status && aircraft.status.map(item => (
                <View key={item.itemName} style={[styles.row]}>
                  <View style={styles.col} >
                    <Text style={styles.fontSmall}>{item.itemName}</Text>
                  </View>
                  <View style={styles.col}>
                    <Text style={styles.fontSmall}>{item.total}</Text>
                  </View>
                  <View style={styles.col}>
                    <Text style={styles.fontSmall}>{item.cycles}</Text>
                  </View>
                  <View style={styles.col}>
                    <Text style={styles.fontSmall}>{moment(item.lastUpdated).format('ll')}</Text>
                  </View>
                </View>
              ))
            }
          </View>
        </View>

        <View style={styles.spacer}>
          <Text style={styles.title}>Mainteinance Inspections</Text>
        </View>

        <Table data={data} columns={columns} />

        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
    </Document>
  )
};

Font.register(
  {
    family: 'Rubik',
    src: '/Rubik-Regular.ttf',
  },
);
Font.register(
  {
    family: 'RubikBold',
    src: '/Rubik-Bold.ttf'
  }
);

