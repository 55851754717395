import React, { CSSProperties, useEffect } from 'react'
import styles from '../styles/Modal.module.scss'

interface Props {
  visible: boolean,
  title?: string,
  onClose?: () => void,
  onOk?: any,
  onCancel?: () => void,
  children: JSX.Element,
  header?: JSX.Element,
  bodyStyle?: CSSProperties,
  loadingState?: boolean
}

const Modal = ({ title, onClose, visible, children, onOk, header, bodyStyle, onCancel, loadingState }: Props) => {

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
  }, [visible])

  return visible ?
    <div className="modal-root">
      <div className={styles.modalBackdrop} />
      <div className={styles.modalWrap}>
        <div style={{
          width: bodyStyle?.width ? bodyStyle.width : 600
        }} className={styles.modal}>
          <div className={styles.modalHeader}>
            <h2>{title}</h2>
            <div onClick={() => {
              if (loadingState) {
                return
              }
              if (onClose) {
                onClose()
              }
            }} className={styles.modalClose}>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>
          </div>
          <div style={{ ...bodyStyle }} className={styles.modalBody}>
            {children}
          </div>
          <div className={styles.modalFooter}>
            <button disabled={loadingState} onClick={onCancel} className='btn btn-outlined-blue'>Cancel</button>
            <button disabled={loadingState} onClick={onOk} className='btn btn-blue'>Ok</button>
          </div>
        </div>
      </div>
    </div> :
    null
}

export default Modal
