import { api } from "../../api/api"

export const postFolder = async (folder: {
  type: string;
  name: any;
  folder: any;
  url: any;
  aircraftId: any;
}) => {
  const { data } = await api.post('/api/documents/folder', folder)
  return data
}
