import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import Card from '../components/Card'
import { AuthContext } from '../context/AuthProvider'
import styles from '../styles/Home.module.scss'

const HomeAdmin = () => {

  const { user } = useContext(AuthContext)

  const links = [
    {
      name: 'Global MTX',
      description: 'Maintenance Tracking',
      icon: <span className="material-symbols-rounded">
        construction
      </span>,
      path: '/mtx/fleet'
    },
    {
      name: 'Global FBO',
      description: 'Flight scheduling',
      icon: <span className="material-symbols-rounded">
        flight_takeoff
      </span>,
      path: '/fbo'
    },
    {
      name: 'Requests',
      description: 'Review platform access requests',
      icon: <span className="material-symbols-rounded">
        fact_check
      </span>,
      path: '/requests'
    },
    {
      name: 'Users',
      description: 'Mange Global Tracking users',
      icon: <span className="material-symbols-rounded">
        group
      </span>,
      path: '/users'
    },
  ]

  return (
    <div className={styles.home}>

      <div className={styles.header}>
        <h3>Hello, {user.name.split(' ')[0]}!</h3>
        <h4>My apps</h4>
      </div>

      <div className={styles.grid}>

        {
          links.map(link => (
            <Card key={link.path} {...link} />
          ))
        }

      </div>

    </div>
  )
}

export default HomeAdmin