import React, { FC } from 'react'
import { Control, Controller, FieldValues } from 'react-hook-form'
import ReactSelect from 'react-select'

interface Props {
  control?: Control<FieldValues, any>,
  options: {
    label: string,
    value: string
  }[],
  name: string,
  errors?: {
    [x: string]: any;
  },
  label: string,
  required?: boolean,
  isMulti?: boolean
  handleChange?: any
}

const Select: FC<Props> = ({ control, options, errors, name, label, required, isMulti, handleChange }) => {
  return (
    <div className="input-group">
      <label>{label}</label>
      <Controller
        name={name}
        control={control}
        rules={{
          required: {
            value: required || false,
            message: 'Required'
          }
        }}
        render={({ field: { onChange, onBlur, value } }) =>
          <ReactSelect
            isMulti={isMulti}
            onChange={(e) => {
              onChange(e)
              handleChange && handleChange(e)
            }}
            onBlur={onBlur}
            value={value}
            placeholder={label}
            isSearchable={true}
            options={options}
          />}
      />
      {
        (errors && errors[name]) &&
        <span className='error'>{errors[name].message}</span>
      }
    </div>
  )
}

export default Select