import { createContext, useState } from 'react'
import { AircraftElement } from '../interfaces/Aircraft'

export interface AircraftContextInterface {
  aircraftElement: AircraftElement,
  setAircraftElement: (aircraft: AircraftElement) => void
}

export const AircraftContext = createContext<AircraftContextInterface>({
  aircraftElement: {} as AircraftElement,
  setAircraftElement: () => null
})

export const AircraftProvider = ({ children }: any) => {

  const [aircraftElement, setAircraftElement] = useState({} as AircraftElement)

  return (
    <AircraftContext.Provider value={{ aircraftElement, setAircraftElement }}>
      {
        children
      }
    </AircraftContext.Provider>
  )
}
