import { useEffect, useState } from 'react'
import styles from '../styles/Users.module.scss'
import { User } from '../interfaces/User'
import Modal from '../components/Modal'
import { Link } from 'react-router-dom'
import toast from 'react-hot-toast'
import Input from '../components/Input'
import Select from '../components/Select'
import Checkbox from '../components/Checkbox'
import { useForm } from 'react-hook-form'
import Table from '../components/Table'
import { getUsers } from '../services/users/getUsers'
import Loading from '../components/Loading'
import { postUser } from '../services/users/postUser'

const Users = () => {

  const [visible, setVisible] = useState(false)

  const [fbo, setFbo] = useState(false)

  const [mtx, setMtx] = useState(false)

  const [users, setUsers] = useState<User[]>([])

  const { control, register, handleSubmit, formState: { errors } } = useForm()

  const [loading, setLoading] = useState(true)

  const mtxRoles = [
    {
      view: '/mtx/fleet',
      name: 'Fleet',
    },
    {
      view: '/mtx/tasks',
      name: 'Tasks',
    },
    {
      view: '/mtx/due-list',
      name: 'Due List',
    },
    {
      view: '/mtx/documents',
      name: 'Documents',
    },
    {
      view: '/mtx/service-bulletins',
      name: 'Service Bulletins',
    },
    {
      view: '/mtx/airworthiness-directives',
      name: 'Airworthiness Directives',
    },
    {
      view: '/mtx/sign-off',
      name: 'Sign Off',
    },
    {
      view: '/mtx/discrepancies',
      name: 'Discrepancies',
    },
  ]

  const fboRoles = [
    {
      view: '/fbo/flights',
      name: 'Flights',
    },
    {
      view: '/fbo/fleet',
      name: 'Fleet',
    },
    {
      view: '/fbo/flight-reports',
      name: 'Flight Reports',
    },
    {
      view: '/fbo/documents',
      name: 'Aircraft Documents',
    },
  ]

  useEffect(() => {
    fetchUsers()
  }, [])

  const openModal = () => {
    setVisible(true)
  }

  const closeModal = () => {
    setVisible(false)
  }

  const onSubmit = async (values: any) => {

    const fboPermissions = values.fboPermissions
    const mtxPermissions = values.mtxPermissions
    let mappedMtx: any[] = []

    let mappedFbo: any[] = []

    if (mtxPermissions) {
      mappedMtx = Object.keys(mtxPermissions).map(role => ({
        view: role,
        permissions: mtxPermissions[role] || [],
      }))
    }

    if (fboPermissions) {
      mappedFbo = Object.keys(fboPermissions).map(role => ({
        view: role,
        permissions: fboPermissions[role] || [],
      }))
      mappedFbo.push({ view: '/fbo', permissions: ['view'] })
    }

    const access = [...mappedMtx, ...mappedFbo, { view: '/', permissions: ['view'] }]

    const postedUser = {
      ...values,
      access: access.filter(role => role.permissions.length > 0),
      reportsTo: values.reportsTo?.value,
      apps: values.apps.map((app: string) => JSON.parse(app))
    }

    try {
      //await api.post('/api/users', postedUser)
      await postUser(postedUser)
      toast.success('Succesfully added new user.')
      setVisible(false)
      fetchUsers()
    } catch (error: any) {
      toast.error(error.response.data.message)
    }
  }

  const fetchUsers = async () => {
    try {
      const data = await getUsers()
      setUsers(data.users)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const columns = [
    {
      title: 'Full name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Position',
      dataIndex: 'position',
      key: 'position',
    },
    {
      title: 'Reports to',
      dataIndex: 'reportsTo',
      key: 'reportsTo',
      render: (text: string, record: User) => record.reportsTo?.name
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (text: string, record: User) => record?.active ? 'Active' : 'Inactive'
    },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      render: (text: string, record: User) => <Link className='btn btn-auto btn-blue' to={`/users/${record.id}`}>View</Link>
    },
  ];

  if (loading) return <Loading />

  return (
    <>
      <div className="header">
        <Link to='/' className='btn btn-link btn-icon'>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
          </svg>
          Back to home
        </Link>
      </div>
      <div className={styles.header}>
        <h2>Users</h2>
        <button onClick={openModal} className='btn btn-blue'>New user</button>
      </div>
      <Table data={users} columns={columns} />
      <Modal
        title="New user"
        onCancel={closeModal}
        onClose={closeModal}
        onOk={handleSubmit(onSubmit)}
        visible={visible}
      >
        <form
        >
          <Input
            name='name'
            label='Full name'
            placeholder='Full name'
            required
            register={register}
            errors={errors}
          />
          <Input
            name='phone'
            label='Phone'
            placeholder='Phone'
            required
            register={register}
            errors={errors}
          />
          <Input
            name='username'
            label='Username'
            placeholder='Username'
            required
            register={register}
            errors={errors}
          />
          <Input
            name='email'
            label='Email'
            placeholder='Email'
            required
            register={register}
            errors={errors}
            type='email'
          />
          <Input
            type='password'
            name='password'
            label='Password'
            placeholder='Password'
            required
            register={register}
            errors={errors}
          />
          <Input
            name='position'
            label='Position'
            placeholder='Position'
            required
            register={register}
            errors={errors}
          />
          <Select
            label='Reports to'
            name='reportsTo'
            control={control}
            options={users.map(user => (
              {
                label: user.name,
                value: user.id
              }
            ))}
            errors={errors}
          />
          <Select
            label='Role'
            name='role'
            required
            control={control}
            options={[
              {
                label: 'Admin',
                value: 'admin'
              },
              {
                label: 'User',
                value: 'user'
              },
            ]}
            errors={errors}
          />
          <Checkbox
            register={register}
            id='active'
            name='active'
            label='Active'
            style={{
              marginBottom: 20
            }}
          />
          <div className={styles.appsWrapper}>
            <span>Application access</span>
            <div className={styles.apps}>
              <div className={styles.app}>
                <Checkbox
                  register={register}
                  id='mtx'
                  label='MTX'
                  name='apps'
                  value={JSON.stringify({
                    label: 'MTX',
                    value: 'mtx'
                  })}
                  onChange={(e: any) => {
                    setMtx(e.target.checked)
                  }}
                  style={{
                    marginBottom: 20
                  }}
                />

                {
                  mtx &&
                  <div className={styles.rolesWrapper}>
                    <span>Select user roles</span>
                    {
                      mtxRoles.map(role => (
                        <div key={role.view} className={styles.viewWrapper}>
                          <div className={styles.view}>
                            <h4>{role.name}</h4>
                          </div>
                          <div className={styles.roles}>
                            <div className={styles.role}>
                              <Checkbox
                                register={register}
                                name={`mtxPermissions[${role.view}]`}
                                label='View'
                                id={`mtxPermissions[${role.view}]-view`}
                                value='view'
                              />
                            </div>
                            <div className={styles.role}>
                              <Checkbox
                                register={register}
                                name={`mtxPermissions[${role.view}]`}
                                label='Create / Edit'
                                id={`mtxPermissions[${role.view}]-create-edit`}
                                value='create-edit'
                              />
                            </div>
                            <div className={styles.role}>
                              <Checkbox
                                register={register}
                                name={`mtxPermissions[${role.view}]`}
                                label='Delete'
                                id={`mtxPermissions[${role.view}]-delete`}
                                value='delete'
                              />
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                }
              </div>
              <div className={styles.app}>
                <Checkbox
                  register={register}
                  id='fbo'
                  label='FBO'
                  name='apps'
                  value={JSON.stringify({
                    label: 'FBO',
                    value: 'fbo'
                  })}
                  onChange={(e: any) => {
                    setFbo(e.target.checked)
                  }}
                  style={{
                    marginBottom: 20
                  }}
                />
                {
                  fbo &&

                  <div className={styles.rolesWrapper}>
                    <span>Select user roles</span>
                    {
                      fboRoles.map(role => (
                        <div key={role.view} className={styles.viewWrapper}>
                          <div className={styles.view}>
                            <h4>{role.name}</h4>
                          </div>
                          <div className={styles.roles}>
                            <div className={styles.role}>
                              <Checkbox
                                register={register}
                                name={`fboPermissions[${role.view}]`}
                                label='View'
                                id={`fboPermissions[${role.view}]-view`}
                                value='view'
                              />
                            </div>
                            <div className={styles.role}>
                              <Checkbox
                                register={register}
                                name={`fboPermissions[${role.view}]`}
                                label='Create / Edit'
                                id={`fboPermissions[${role.view}]-create-edit`}
                                value='create-edit'
                              />
                            </div>
                            <div className={styles.role}>
                              <Checkbox
                                register={register}
                                name={`fboPermissions[${role.view}]`}
                                label='Delete'
                                id={`fboPermissions[${role.view}]-delete`}
                                value='delete'
                              />
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                }
              </div>
            </div>
          </div>

        </form>
      </Modal>
    </>
  )
}

export default Users
