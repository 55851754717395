import { api } from '../api/api'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import SubLayout from '../components/SubLayout'
import { DiscrepancyElement } from '../interfaces/Discrepancy'
import { Menu } from 'antd'
import { AircraftContext } from '../context/AircraftProvider'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { Report } from '../components/Report'
import { discrepancyColumns } from '../helpers/discrepancyColumns'
import PermissionWrapper from '../components/PermissionWrapper'
import { getDiscrepancies, postDiscrepancy } from '../services/discrepancies'
import { toast } from 'react-hot-toast'
import Table from '../components/Table'
import { useForm } from 'react-hook-form'
import Input from '../components/Input'
import TextArea from '../components/TextArea'
import DatePicker from '../components/DatePicker'
import Modal from '../components/Modal'
import Loading from '../components/Loading'

const Discrepancies = () => {

  const discrepanciesColumns = [
    {
      title: 'Number',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Man hours',
      dataIndex: 'manHours',
      key: 'manHours',
    },
    {
      title: 'Created date',
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (text: string) => <>{moment(text).format('lll')}</>,
    },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      render: (text: string, record: DiscrepancyElement) => <Link className='btn btn-blue' to={`/aircraft/${aircraftId}/discrepancies/${record.id}`}>View</Link>
    },
  ]
  const { aircraftId } = useParams<any>()

  const [discrepancies, setDiscrepancies] = useState<DiscrepancyElement[]>([])
  const { aircraftElement, setAircraftElement } = useContext(AircraftContext)
  const [doc, setDoc] = useState(false)

  const { register, control, handleSubmit, reset, formState: { errors } } = useForm()

  const [loading, setLoading] = useState(true)

  const [dateIn, setDateIn] = useState(new Date())

  const [dateOut, setDateOut] = useState(new Date())

  useEffect(() => {
    if (!aircraftElement) {
      api.get(`/api/aircraft/${aircraftId}`).then(({ data }) => {
        setAircraftElement(data.aircraft)

      }).catch(error => console.log(error))
    }

  }, [aircraftId, setAircraftElement, aircraftElement])
  const [temp, setTemp] = useState<DiscrepancyElement[]>([])

  useEffect(() => {
    fetchDiscrepancies()
  }, [])

  const openModal = () => {
    setVisible(true)
  }

  const closeModal = () => {
    setVisible(false)
    reset()
    //form.resetFields()
  }
  const [visible, setVisible] = useState(false)

  const menu = (
    <Menu>
      <Menu.Item
        onClick={openModal}
        key='New Discrepancy'>
        New Discrepancy
      </Menu.Item>
      {
        doc ?
          <PDFDownloadLink
            document={<Report
              logo={aircraftElement.companyLogo}
              columns={discrepanciesColumns}
              aircraft={aircraftElement}
              title='Discrepancies report'
              data={discrepancies}
            />
            }
            fileName='Discrepancies_report.pdf'>
            {({ loading }) =>
              loading ? 'Loading document...' : <Menu.Item >Download report</Menu.Item>
            }
          </PDFDownloadLink>
          :

          <Menu.Item
            onClick={() => { setDoc(true) }}
          >
            Generate report
          </Menu.Item>

      }
    </Menu>
  );

  const search = (e: any) => {
    setDiscrepancies(temp.filter(item => item.title.toLowerCase().includes(e.target.value.toLowerCase())))
  }

  const content = (
    <div
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <div style={{
        marginRight: 10,
        flex: 1
      }}>
        <input
          className='input'
          onChange={search}
          style={{ width: '100%' }}
          placeholder='Search discrepancies' />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <PermissionWrapper
          role='create-edit'
          view='/mtx/discrepancies'
        >
          <button
            style={{ marginRight: 10 }}
            onClick={openModal}
            className='btn btn-blue'
          >
            New Discrepancy
          </button>
        </PermissionWrapper>
        <div>
          {
            doc ? <div className="download-btn-wrapper">
              <PDFDownloadLink
                document={<Report
                  logo={aircraftElement.companyLogo}
                  columns={discrepancyColumns}
                  aircraft={aircraftElement}
                  title='Discrepancies report'
                  data={discrepancies}
                />
                }
                fileName={`Discrepancies_report.pdf`}>
                {({ loading }) =>
                  loading ? 'Loading document...' : <button className='btn btn-blue'>Download report</button>
                }
              </PDFDownloadLink>

            </div> :

              <button
                className='btn btn-blue'
                onClick={() => { setDoc(true) }}
              >
                Generate report
              </button>

          }
        </div>
      </div>
    </div>
  )

  const mobileSearch = (
    <input
      className='input'
      onChange={search}
      placeholder='Search'
    />
  )

  const fetchDiscrepancies = async () => {
    try {
      const data = await getDiscrepancies(aircraftId)
      setDiscrepancies(data.discrepancies)
      setTemp(data.discrepancies)
      setLoading(false)
    } catch (error: any) {
      toast.error(error.response.data.message)
      setLoading(false)
    }
  }

  const createDiscrepancy = async (values: any) => {
    const update = {
      status: 'New',
      aircraft: aircraftId,
      ...values
    }

    try {
      await postDiscrepancy(update)
      toast.success('Created discrepancy')
      fetchDiscrepancies()
      closeModal()
    } catch (error: any) {
      toast.error(error.response.data.message)
    }
  }
  /*  const createDiscrepancy = () => {
     form.validateFields().then((values) => {
       api.post('/api/discrepancies', {
         ...values,
         dateIn: values.dateInOut[0],
         dateOut: values.dateInOut[1],
         status: 'New',
         aircraft: aircraftId
       }).then(() => {
         message.success('Discrepancy created')
         closeModal()
         getAircraftDiscrepancies()
       })
     })
   
 
   } */

  return (
    <>
      <SubLayout
        menu={menu}
        content={content}
        title='Discrepancies'
        mobileSearch={mobileSearch}
      >
        {
          loading && <Loading />
        }
        {
          !loading &&
          <Table
            data={discrepancies}
            columns={discrepanciesColumns}
          />
        }
      </SubLayout>
      <Modal
        visible={visible}
        onCancel={closeModal}
        title='New Discrepancy'
        bodyStyle={{
          height: '40vh',
          overflowY: 'scroll'
        }}
        onOk={handleSubmit(createDiscrepancy)}
      >
        <>
          <Input
            name='number'
            label='Number'
            placeholder='Number'
            errors={errors}
            register={register}
            required
          />
          <Input
            name='title'
            label='Title'
            placeholder='Title'
            errors={errors}
            register={register}
            required
          />
          <TextArea
            name='description'
            label='Description'
            placeholder='Description'
            errors={errors}
            register={register}
            required
          />
          <DatePicker
            label='Date in'
            name='dateIn'
            selectedDate={dateIn}
            setSelectedDate={setDateIn}
            required
            control={control}
            errors={errors}
            minDate={undefined}
          />
          <DatePicker
            label='Date out'
            name='dateOut'
            selectedDate={dateOut}
            setSelectedDate={setDateOut}
            required
            control={control}
            errors={errors}
            minDate={undefined}
          />
          <Input
            name='manHours'
            label='Man hours'
            placeholder='Man hours'
            errors={errors}
            register={register}
            required
          />
        </>
      </Modal>
    </>

  )
}

export default Discrepancies
