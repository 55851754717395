import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({

  spacer: {
    marginBottom: 10
  },
  fontSmall: {
    fontSize: 8,
  },
  fontSmallBold: {
    fontSize: 8,
    fontFamily: 'RubikBold',
  },

  body: {
    paddingTop: 25,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  image: {
    width: 100,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  title: {
    fontSize: 12,
    fontFamily: 'RubikBold'
  },

  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },

  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    flexDirection: 'row',
  },
  col: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    padding: 5
  },
  borderBottom: {
    borderBottomWidth: 0.8,
    borderBottomColor: 'gray'
  },
  col2: {
    width: '50%'
  }


});
