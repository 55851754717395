import React from 'react'

const FormGroup = ({ children, methods, errors }: any) => {

  return children.map((child: any) => {
    return child.props.name
      ? React.createElement(child.type, {
        ...{
          ...child.props,
          register: methods.register,
          control: methods.control,
          key: child.props.name,
          errors
        }
      })
      : child;
  })

}

export default FormGroup