import { createContext, useEffect, useState } from 'react'
import { api } from '../api/api'
import { User } from '../interfaces/User'

export interface AuthContextInterface {
  user: User,
  setUser: (user: User) => void,
  loading: boolean,
}

export const AuthContext = createContext({} as AuthContextInterface)

export const AuthProvider = ({ children }: any) => {

  const [user, setUser] = useState({} as User)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function fetchData() {
      const token = localStorage.getItem('token')
      try {
        const { data } = await api.get<any>('/api/auth/renew/', {
          headers: {
            'Content-type': 'application/json',
            'x-token': token || ''
          }
        })
        setUser(data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }
    fetchData();
  }, []); // Or [] if effect doesn't need props or state

  return (
    <AuthContext.Provider value={{ user, setUser, loading }}>
      {
        children
      }
    </AuthContext.Provider>
  )
}
