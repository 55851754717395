import { api } from '../../api/api'
import { AWDirective } from '../../interfaces/AWDirective'

export const getDirectives = async (aircraftId: string) => {
  const { data } = await api.get<any>(`/api/airworthiness-directives/aircraft/${aircraftId}`)

  return data
}

export const postDirective = async (directive: AWDirective) => {
  await api.post('/api/airworthiness-directives', directive)
}
