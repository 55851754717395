import { FieldValues, UseFormRegister } from 'react-hook-form'

interface Props {
  placeholder?: string,
  name: string,
  register?: UseFormRegister<FieldValues>,
  required?: boolean,
  defaultValue?: any,
  errors?: any,
  minLength?: number,
  pattern?: any,
  label: string
}

const TextArea = ({ placeholder, pattern, register, required, minLength, name, defaultValue, errors, label }: Props) => {
  return (
    <div className='input-group'>
      <label htmlFor={name}>{label}</label>
      <textarea
        className='input'
        defaultValue={defaultValue}
        {

        ...register &&
        {
          ...register(name, {
            required: {
              value: required || false,
              message: 'Required'
            },
            minLength: {
              value: minLength || 0,
              message: 'Minimo ' + minLength + ' caracteres'
            },
            pattern: {
              value: pattern,
              message: 'No valido'
            }

          })
        }
        }
        autoComplete='new-password'
        placeholder={placeholder}
      />
      {
        (errors && errors[name]) &&
        <span className='error'>{errors[name].message}</span>
      }
    </div>
  )
}

export default TextArea
