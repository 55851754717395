import moment from "moment"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { Link, useHistory, useParams } from "react-router-dom"
import { api } from "../../../api/api"
import Checkbox from "../../../components/Checkbox"
import DatePicker from "../../../components/DatePicker"
import Input from "../../../components/Input"
import Loading from "../../../components/Loading"
import Modal from "../../../components/Modal"
import Select from "../../../components/Select"
import TextArea from "../../../components/TextArea"
import { TimePicker } from "../../../components/TimePicker"
import { AircraftElement } from "../../../interfaces/Aircraft"
import { Flight } from "../../../interfaces/Flight"
import { User } from "../../../interfaces/User"
import { getFleet } from "../../../services/aircraft/getFleet"
import { getFlight } from "../../../services/flights/getFlight"
import { putFlight } from "../../../services/flights/putFlight"
import { getUsers } from "../../../services/users/getUsers"

const FlightDetails = () => {

  const { id } = useParams<{ id: string }>()

  const [confirm, setConfirm] = useState(false)

  const [editing, setEditing] = useState(false)

  const { register, handleSubmit, reset, control } = useForm()

  const [arrivalDate, setArrivalDate] = useState(new Date())

  const [departureDate, setDepartureDate] = useState(new Date())

  const { replace } = useHistory()

  const [flight, setFlight] = useState({} as Flight)

  const [aircraft, setAircraft] = useState<AircraftElement[]>([])

  const [loading, setLoading] = useState(true)

  const [users, setUsers] = useState<User[]>([])

  const fetchFlight = async () => {
    try {
      const data = await getFlight(id)
      setFlight(data.flight)
      let flight: Flight = data.flight
      reset({
        ...flight,
        aircraft: {
          label: flight.aircraft?.registrationNumber,
          value: flight.aircraft._id
        },
        arrivalDate: flight.arrival.date,
        arrivalTime: moment(flight.arrival.time),
        arrivalLocation: flight.arrival.location,
        departureDate: flight.departure.date,
        departureTime: moment(flight.departure.time),
        departureLocation: flight.departure.location,
        crew: flight.crew.map(user => ({
          label: user.name,
          value: user._id
        }))
      })

      setLoading(false)
    } catch (error: any) {
      console.log(error);
    }
  }

  const fetchFleet = async () => {
    const data = await getFleet('fbo')
    setAircraft(data.aircraft)
  }

  const fetchUsers = async () => {
    const data = await getUsers()
    setUsers(data.users)
  }

  useEffect(() => {
    fetchFleet()
    fetchUsers()
    fetchFlight()
  }, [])

  const renderForm = () => {
    return (
      <div
      >
        <Select
          name='aircraft'
          label='Aircraft'
          required
          options={aircraft?.map(item => (
            {
              label: item.registrationNumber,
              value: item.id
            }
          ))}
          control={control}

        />
        <div>
          <h3>Departure</h3>
          <DatePicker
            name='departureDate'
            label='Departure date'
            required
            selectedDate={departureDate}
            setSelectedDate={setDepartureDate}
            control={control}
            minDate={new Date()}
          />
          <TimePicker
            name='departureTime'
            label='Departure time'
            placeholder='Departure time'
            required
            control={control}
          />
          <Input
            name='departureLocation'
            label='Location'
            placeholder='Location'
            required
            register={register}
          />
        </div>
        <div>
          <h3>Arrival</h3>
          <DatePicker
            name='arrivalDate'
            label='Arrival date'
            required
            selectedDate={arrivalDate}
            setSelectedDate={setArrivalDate}
            control={control}
            minDate={new Date()}
          />
          <TimePicker
            name='arrivalTime'
            label='Arrival time'
            placeholder='Arrival time'
            required
            control={control}
          />
          <Input
            name='arrivalLocation'
            label='Location'
            placeholder='Location'
            required
            register={register}
          />
        </div>
        <Select
          name='crew'
          label='Crew'
          required
          isMulti
          options={users?.map(item => (
            {
              label: item.name,
              value: item.id
            }
          ))}
          control={control}
        />
        <Checkbox
          style={{
            marginBottom: 20
          }}
          label='Preflight'
          name='preflight'
          id='preflight'
          register={register}
          defaultChecked={flight?.preflight}
        />
        <Checkbox
          style={{
            marginBottom: 20
          }}
          label='Catering'
          name='catering'
          id='catering'
          register={register}
          defaultChecked={flight?.catering}
        />
        <Checkbox
          style={{
            marginBottom: 20
          }}
          label='Transportation'
          name='transportation'
          id='transportation'
          register={register}
          defaultChecked={flight?.transportation}
        />
        <TextArea
          name='specialInstructions'
          label='Special Instructions'
          placeholder='Special Instructions'
          register={register}
        />
      </div>
    )
  }

  const onSubmit = async (values: any) => {

    const postedFlight: Flight = {
      ...values,
      departure: {
        date: values.departureDate,
        time: values.departureTime,
        location: values.departureLocation
      },
      arrival: {
        date: values.arrivalDate,
        time: values.arrivalTime,
        location: values.arrivalLocation
      },
      crew: values.crew.map((user: any) => user.value),
      aircraft: values.aircraft.value
    }
    try {
      await putFlight(id, postedFlight)
      toast.success('Sucessfully updated flight')
      setEditing(false)
      fetchFlight()
    } catch (error: any) {
      toast.error(error.response.data.message)
    }
  }

  if (loading) return <Loading />

  return (
    <>
      <div className="detailPage">
        <div className="header">
          <Link to='/fbo/flights' className='btn btn-link btn-icon'>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
            </svg>
            Back to Flights
          </Link>
        </div>
        <div className="header">
          <h2>Flight details</h2>
          <div className="header">
            <button
              onClick={() => {
                setEditing(true)
              }}
              style={{ marginRight: 10 }} className="btn btn-blue">Edit</button>
            <button onClick={() => {
              setConfirm(true)
            }} className="btn btn-outlined-blue">Delete</button>
          </div>
        </div>

        <div className="content">

          <div className="topContent">
            <div className="topContentItem">
              <span>Departure</span>
              <span>{flight.departure.location} | {moment(flight.departure.date).format('ll')} | {moment(flight.departure.time).format('hh:mm a')}</span>
            </div>
            <div className="topContentItem">
              <span>Preflight</span>
              <span>{flight.preflight ? 'Yes' : 'No'}</span>
            </div>
            <div className="topContentItem">
              <span>Catering</span>
              <span>{flight.catering ? 'Yes' : 'No'}</span>
            </div>
            <div className="topContentItem">
              <span>Transportation</span>
              <span>{flight.transportation ? 'Yes' : 'No'}</span>
            </div>
          </div>

          <div className="item">
            <h3>Aircraft details</h3>
            <div className="propertyItem">
              <span>Registration number</span>
              <span>{flight.aircraft.registrationNumber}</span>
            </div>
          </div>
          <div className="item">
            <h3>Crew details</h3>
            <div className="propertyItem">
              {flight.crew.map(user => (
                <span key={user._id}>{user.name}</span>
              ))}
            </div>
          </div>
          <div className="item">
            <h3>Departure</h3>
            <div className="propertyItem">
              <span>Date & time</span>
              <span>{moment(flight.departure.date).format('ll')} | {moment(flight.departure.time).format('hh:mm a')}</span>
            </div>
            <div className="propertyItem">
              <span>Location</span>
              <span>{flight.departure.location}</span>
            </div>
          </div>
          <div className="item">
            <h3>Arrival</h3>
            <div className="propertyItem">
              <span>Date & time</span>
              <span>{moment(flight.arrival.date).format('ll')} | {moment(flight.arrival.time).format('hh:mm a')}</span>
            </div>
            <div className="propertyItem">
              <span>Location</span>
              <span>{flight.arrival.location}</span>
            </div>
          </div>
          <div className="item">
            <h3>Special instructions</h3>
            <div className="propertyItem">
              <span>{flight.specialInstructions}</span>
            </div>
          </div>
        </div>

      </div>
      <Modal
        visible={confirm}
        bodyStyle={{
          height: 'fit-content'
        }}
        onCancel={() => {
          setConfirm(false)
        }}
        onClose={() => {
          setConfirm(false)
        }}

        onOk={async () => {
          try {
            await api.delete(`/api/flights/${id}`)
            toast.success('Deleted flight sucesfully.')
            replace('/flights')
          } catch (error: any) {
            toast.error(error.response.data.message)
          }
        }}
      >
        <h3>Are you sure to delete this flight?</h3>
      </Modal>

      <Modal
        title="Edit flight"
        visible={editing}
        onCancel={() => {
          setEditing(false)
        }}
        onOk={handleSubmit(onSubmit)}
        onClose={() => {
          setEditing(false)
        }}
      >
        {renderForm()}
      </Modal>
    </>
  )
}

export default FlightDetails