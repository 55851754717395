export const menuLinks = [
  {
    path: 'documents/root',
    name: 'Documents',
    icon: <span className="material-symbols-rounded">
      description
    </span>,
    generalPath: '/mtx/documents',
    apps: ['mtx', 'fbo']
  },
  {
    path: 'tasks',
    name: 'Tasks / Status',
    icon: <span className="material-symbols-rounded">
      fact_check
    </span>,
    generalPath: '/mtx/tasks',
    apps: ['mtx']
  },
  {
    path: 'service-bulletins',
    name: 'Service Bulletins',
    icon: <span className="material-symbols-rounded">
      fact_check
    </span>,
    generalPath: '/mtx/service-bulletins',
    apps: ['mtx']

  },
  {
    path: 'airworthiness-directives',
    name: 'Airworthiness Directives',
    icon: <span className="material-symbols-rounded">
      fact_check
    </span>,
    generalPath: '/mtx/airworthiness-directives',
    apps: ['mtx']

  },
  {
    path: 'due-list',
    name: 'Due List',
    icon: <span className="material-symbols-rounded">
      free_cancellation
    </span>,
    generalPath: '/mtx/due-list',
    apps: ['mtx']
  },
  {
    path: 'sign-off',
    name: 'Sign Off',
    icon: <span className="material-symbols-rounded">
      description
    </span>,
    generalPath: '/mtx/sign-off',
    apps: ['mtx']
  },
  {
    path: 'work-orders',
    name: 'Work Orders',
    icon: <span className="material-symbols-rounded">
      construction
    </span>,
    generalPath: '/mtx/work-orders',
    apps: ['mtx']
  },
  {
    path: 'discrepancies',
    name: 'Discrepancies',
    icon: <span className="material-symbols-rounded">
      cancel
    </span>,
    generalPath: '/mtx/discrepancies',
    apps: ['mtx']
  },
]