import {
  Route,
  Redirect
} from 'react-router-dom';

function PublicRoute({ children, isAuthenticated, ...rest }: any) {

  return (
    <Route
      {...rest}
      render={
        () => (
          !isAuthenticated ?
            children
            :
            <Redirect
              to='/'
            />
        )
      }
    />
  );
}
export default PublicRoute;