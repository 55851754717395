import { useContext, useState } from 'react'
import styles from '../styles/Login.module.scss'
import { api } from '../api/api';
import { AuthContext } from '../context/AuthProvider';
import toast from 'react-hot-toast';
import Input from '../components/Input';
import { Form } from '../components/Form';

export const Login = () => {

  const [resetPassword, setResetPassword] = useState(false)

  const { setUser } = useContext(AuthContext)

  const passwordReset = ({ email }: any) => {
    api.post('/api/auth/recover', { email }).then(() => {
      toast.success('We sent password reset instructions to the email you provided.')
    }).catch((e) => {
      toast.error(e.response.data.message)
    })
  }

  const login = async ({ email, password }: any) => {
    try {
      const { data } = await api.post('/api/auth/login', { email, password })
      setUser(data)
      localStorage.setItem('token', data.token)
      localStorage.setItem('token-init-date', new Date().getTime().toString())
    } catch (error: any) {
      toast.error(error.response.data.message)
    }
  }

  return (
    <div className={styles.authContainer}>
      <div className={styles.left}>
        <Form onSubmit={login}>
          <Input
            placeholder='Your email'
            label='Email'
            name="email"
            required />
          <Input
            placeholder='Your password'
            type='password'
            label='Password'
            name="password"
            required />
          <button className='btn btn-blue btn-block placeho'>Log in</button>
        </Form>

      </div>
      <div className={styles.right}>
        <img alt="Login Cover" className={styles.plane} src='/Cessna_Skyhawk_Image1.jpg' />
        <div className={styles.overlay}>
          <img alt="Global Tracking Logo" src='/global-tracking-logo-white.png' />
        </div>
      </div>
    </div>
  )
}
