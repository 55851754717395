import { Tag, Table } from 'antd'
import { api } from '../api/api'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import SubLayout from '../components/SubLayout'
import { VerticalTable } from '../components/VerticalTable'
import { Task } from '../interfaces/Task'
import { WorkOrderElement } from '../interfaces/WorkOrder'

const WorkOrderDetail = () => {

  const workOrderColumns = [
    {
      title: 'Number',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Date In',
      dataIndex: 'dateIn',
      key: 'dateIn',
      render: (text: string) => moment(text).format('ll')
    },
    {
      title: 'Date Out',
      dataIndex: 'dateOut',
      key: 'dateOut',
      render: (text: string) => moment(text).format('ll')
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Items',
      dataIndex: 'items',
      key: 'items',
      render: (text: string, record: WorkOrderElement) => record.items?.length
    },
  ]

  const [workOrder, setWorkOrder] = useState<any>({})

  const { workOrderId, aircraftId } = useParams<any>()

  const columns = [
    {
      title: 'ATA',
      dataIndex: 'ata',
      key: 'ata',
    },
    {
      title: 'Task description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text: string, record: Task) => <Tag color={record.status === 'OK' ? 'green' : 'red'} >{text}</Tag>
    },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      render: (text: string, record: Task) => <Link className='btn btnBlue' to={`/aircraft/${aircraftId}/tasks/${record._id}`}>View</Link>
    },
  ]

  useEffect(() => {
    api.get<any>(`/api/work-orders/${workOrderId}`).then(({ data }) => {
      setWorkOrder(data.workOrder)
    }).catch(error => console.log(error))
  }, [workOrderId])

  return (
    <SubLayout title='Work order detail'>
      <div>
        <span>{workOrder.number}</span>
        <h3>{workOrder.title}</h3>
        <span>{workOrder.description}</span>
        <div className="mobileTable">
          <VerticalTable data={[workOrder]} columns={workOrderColumns} />
        </div>

        <div className="webTable">
          <Table dataSource={[workOrder]} columns={workOrderColumns} />
        </div>
        <div>
          <h2>Work order items</h2>
          <div className="webTable">
            <Table dataSource={workOrder.items} columns={columns} />
          </div>
          <div className="mobileTable">
            <VerticalTable data={workOrder.items} columns={columns} />
          </div>
        </div>
      </div>
    </SubLayout>
  )
}

export default WorkOrderDetail
