import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom'
import { api } from '../api/api';
import Modal from '../components/Modal';
import styles from '../styles/UserDetails.module.scss'
import { User } from '../interfaces/User';
import toast from 'react-hot-toast';
import { Document } from '../interfaces/Document';
import moment from 'moment';
import Input from '../components/Input';
import Select from '../components/Select';
import Checkbox from '../components/Checkbox';
import UploadDocumentModal from '../components/UploadDocumentModal';
import Table from '../components/Table';
import Loading from '../components/Loading';

const UserDetails = () => {

  const { id } = useParams<{ id: string }>()

  const { register, handleSubmit, control, reset, formState: { errors } } = useForm();

  const [users, setUsers] = useState<User[]>([])

  const [user, setUser] = useState({} as User)

  const [loading, setLoading] = useState(true)

  const [documents, setDocuments] = useState<Document[]>([])

  const [editing, setEditing] = useState(false)

  const userAccess = user.access

  const [visible, setVisible] = useState(false)

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: Document) => <a
        style={{
          fontWeight: 500,
          color: '#0E75BC'
        }}
        target='_blank' rel='noopener noreferrer' href={record.url}>{text}</a>
    },
    {
      title: 'Start date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (text: string) => moment(text).format('ll')
    },
    {
      title: 'Due date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      render: (text: string) => moment(text).format('ll')
    },
    {
      title: 'Last updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text: string) => moment(text).format('lll')
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
  ]

  const fetchData = useCallback(async () => {
    const { data } = await api.get(`/api/users/${id}`)
    const { data: usersData } = await api.get(`/api/users/`)
    const { data: documentsData } = await api.get(`/api/documents/${id}`)
    setDocuments(documentsData.documents)
    setUser(data.user)
    setUsers(usersData.users)
    setLoading(false)
    reset({
      ...data.user,
      reportsTo: {
        value: data.user.reportsTo?._id,
        label: data.user.reportsTo?.name
      },
      apps: data.user.apps?.map((app: any) => JSON.stringify(app))
    })
  }, [id, reset])

  const mtxRoles = [
    {
      view: '/mtx/fleet',
      name: 'Fleet',
    },
    {
      view: '/mtx/tasks',
      name: 'Tasks',
    },
    {
      view: '/mtx/due-list',
      name: 'Due List',
    },
    {
      view: '/mtx/documents',
      name: 'Documents',
    },
    {
      view: '/mtx/service-bulletins',
      name: 'Service Bulletins',
    },
    {
      view: '/mtx/airworthiness-directives',
      name: 'Airworthiness Directives',
    },
    {
      view: '/mtx/sign-off',
      name: 'Sign Off',
    },
    {
      view: '/mtx/discrepancies',
      name: 'Discrepancies',
    },
  ]

  const fboRoles = [
    {
      view: '/fbo/flights',
      name: 'Flights',
    },
    {
      view: '/fbo/fleet',
      name: 'Fleet',
    },
    {
      view: '/fbo/flight-reports',
      name: 'Flight Reports',
    },
    {
      view: '/fbo/documents',
      name: 'Aircraft Documents',
    },
  ]

  useEffect(() => {
    fetchData()
  }, [id, fetchData])


  const onEdit = async (values: any) => {
    const fboPermissions = values.fboPermissions
    const mtxPermissions = values.mtxPermissions
    let mappedMtx: any[] = []

    let mappedFbo: any[] = []

    if (mtxPermissions) {
      mappedMtx = Object.keys(mtxPermissions).map(role => ({
        view: role,
        permissions: mtxPermissions[role] || [],
      }))
    }

    if (fboPermissions) {
      mappedFbo = Object.keys(fboPermissions).map(role => ({
        view: role,
        permissions: fboPermissions[role] || [],
      }))
      mappedFbo.push({ view: '/fbo', permissions: ['view'] })
    }

    const access = [...mappedMtx, ...mappedFbo, { view: '/', permissions: ['view'] }]

    const postedUser = {
      ...values,
      access: access.filter(role => role?.permissions.length > 0),
      reportsTo: values.reportsTo?.value,
      apps: values.apps.map((app: string) => JSON.parse(app))
    }

    try {
      await api.put(`/api/users/${id}`, postedUser)
      toast.success('Changes saved.')
      fetchData()
      setEditing(false)
    } catch (error: any) {
      toast.error(error.response.data.message)
    }
  }

  const renderForm = () => {
    return (
      <form
      >
        <Input
          name='name'
          label='Full name'
          placeholder='Full name'
          required
          register={register}
          errors={errors}
        />
        <Input
          name='phone'
          label='Phone'
          placeholder='Phone'
          required
          register={register}
          errors={errors}
        />
        <Input
          name='username'
          label='Username'
          placeholder='Username'
          required
          register={register}
          errors={errors}
        />
        <Input
          name='email'
          label='Email'
          placeholder='Email'
          required
          register={register}
          errors={errors}
          type='email'
        />
        <Input
          name='position'
          label='Position'
          placeholder='Position'
          required
          register={register}
          errors={errors}
        />
        <Select
          label='Reports to'
          name='reportsTo'
          control={control}
          options={users.map(user => (
            {
              label: user.name,
              value: user.id
            }
          ))}
          errors={errors}
        />
        <Select
          label='Role'
          name='role'
          required
          control={control}
          options={[
            {
              label: 'Admin',
              value: 'admin'
            },
            {
              label: 'User',
              value: 'user'
            },
          ]}
          errors={errors}
        />
        <Checkbox
          register={register}
          defaultChecked={user.active}
          id='active'
          name='active'
          label='Active'
          style={{
            marginBottom: 20
          }}
        />
        <div className={styles.appsWrapper}>
          <span>Application access</span>
          <div className={styles.apps}>
            <div className={styles.app}>
              <Checkbox
                register={register}
                id='mtx'
                label='MTX'
                name='apps'
                value={JSON.stringify({
                  label: 'MTX',
                  value: 'mtx'
                })}
                style={{
                  marginBottom: 20
                }}
              />


              <div className={styles.rolesWrapper}>
                <span>Select user roles</span>
                {
                  mtxRoles.map(role => (
                    <div key={role.view} className={styles.viewWrapper}>
                      <div className={styles.view}>
                        <h4>{role.name}</h4>
                      </div>
                      <div className={styles.roles}>
                        <div className={styles.role}>
                          <Checkbox
                            register={register}
                            name={`mtxPermissions[${role.view}]`}
                            label='View'
                            id={`mtxPermissions[${role.view}]-view`}
                            value='view'
                            defaultChecked={
                              userAccess.find(view => view.view === role.view)?.permissions.includes('view')
                            }
                          />
                        </div>
                        <div className={styles.role}>
                          <Checkbox
                            register={register}
                            name={`mtxPermissions[${role.view}]`}
                            label='Create / Edit'
                            id={`mtxPermissions[${role.view}]-create-edit`}
                            value='create-edit'
                            defaultChecked={
                              userAccess.find(view => view.view === role.view)?.permissions.includes('create-edit')
                            }
                          />
                        </div>
                        <div className={styles.role}>
                          <Checkbox
                            register={register}
                            name={`mtxPermissions[${role.view}]`}
                            label='Delete'
                            id={`mtxPermissions[${role.view}]-delete`}
                            value='delete'
                            defaultChecked={
                              userAccess.find(view => view.view === role.view)?.permissions.includes('delete')
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>

            </div>
            <div className={styles.app}>
              <Checkbox
                register={register}
                id='fbo'
                label='FBO'
                name='apps'
                value={JSON.stringify({
                  label: 'FBO',
                  value: 'fbo'
                })}
                style={{
                  marginBottom: 20
                }}
              />

              <div className={styles.rolesWrapper}>
                <span>Select user roles</span>
                {
                  fboRoles.map(role => (
                    <div key={role.view} className={styles.viewWrapper}>
                      <div className={styles.view}>
                        <h4>{role.name}</h4>
                      </div>
                      <div className={styles.roles}>
                        <div className={styles.role}>
                          <Checkbox
                            register={register}
                            name={`fboPermissions[${role.view}]`}
                            label='View'
                            id={`fboPermissions[${role.view}]-view`}
                            value='view'
                            defaultChecked={
                              userAccess.find(view => view.view === role.view)?.permissions.includes('view')
                            }
                          />
                        </div>
                        <div className={styles.role}>
                          <Checkbox
                            register={register}
                            name={`fboPermissions[${role.view}]`}
                            label='Create / Edit'
                            id={`fboPermissions[${role.view}]-create-edit`}
                            value='create-edit'
                            defaultChecked={
                              userAccess.find(view => view.view === role.view)?.permissions.includes('create-edit')
                            }
                          />
                        </div>
                        <div className={styles.role}>
                          <Checkbox
                            register={register}
                            name={`fboPermissions[${role.view}]`}
                            label='Delete'
                            id={`fboPermissions[${role.view}]-delete`}
                            value='delete'
                            defaultChecked={
                              userAccess.find(view => view.view === role.view)?.permissions.includes('delete')
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>

            </div>
          </div>
        </div>

      </form>
    )
  }

  if (loading) return <Loading />

  return (
    <>
      <div>
        <div className="header">
          <Link to='/users' className='btn btn-link btn-icon'>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
            </svg>
            Back to Users
          </Link>
        </div>
        <div className={styles.userDetails}>
          <div className={styles.left}>
            <div className={styles.header}>
              <h2>{user.name}</h2>
              <div className={styles.actions}>
                <button
                  onClick={() => {
                    setEditing(true)
                  }}

                  className='btn btn-blue'>Edit</button>
              </div>
            </div>
            <span>{user.username}</span>
            <span>{user.email}</span>
            <div className={styles.roles}>
              <h3>User roles</h3>
              {
                user.access.map(role => (
                  <div className={styles.roleWrapper} >
                    <span className={styles.roleView} >{role.view}</span>
                    <div className={styles.permissionsWrapper}>
                      {
                        Array.isArray(role.permissions) ? role.permissions?.map((permission) => (
                          <span className={styles.rolePermission}>-{permission}</span>
                        )) : role.permissions
                      }
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
          <div className={styles.right}>
            <div className={styles.header}>
              <h2>Documents</h2>
              <div className={styles.actions}>
                <button onClick={() => setVisible(true)} className='btn btn-blue'>New document</button>
              </div>
            </div>
            <div className="content">
              <Table
                data={documents}
                columns={columns}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        title='Edit user'
        visible={editing}
        onCancel={() => { setEditing(false) }}
        onClose={() => { setEditing(false) }}
        onOk={handleSubmit(onEdit)}
      >
        <>
          {
            editing && renderForm()
          }
        </>
      </Modal>

      <UploadDocumentModal
        visible={visible}
        setVisible={setVisible}
      />
    </>
  )
}

export default UserDetails