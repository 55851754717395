import { Link } from 'react-router-dom'
import { AircraftElement } from '../interfaces/Aircraft'
import styles from '../styles/AircraftItem.module.scss'

const AircraftItem = ({ image, registrationNumber, serialNumber, model, analyst, id, operator, app }: AircraftElement) => {
  return (
    <Link to={`/${app.value}/aircraft/${registrationNumber}`}
      className={styles.aircraft}>
      <div className={styles.image}>
        <img alt="Aircraft" src={image} />
      </div>
      <div className={styles.data}>
        <span className={styles.reg}>{model}</span>
        <h3 className={styles.model}>{registrationNumber}</h3>
        <span>{serialNumber}</span>
        <div>
          <span>Analyst</span>
          <span>{analyst.name}</span>
        </div>
        <div>
          <span>Operator</span>
          <span>{operator.name}</span>
        </div>
      </div>
    </Link>
  )
}

export default AircraftItem
