import { PencilIcon } from '@heroicons/react/outline';
import { Collapse, Tooltip } from 'antd'
import { api } from '../../../api/api';
import moment from 'moment';
import { useContext, useRef, useState } from 'react';
import { useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { menuLinks } from '../../../helpers/helpers';
import { AircraftElement, Status } from '../../../interfaces/Aircraft';
import styles from '../../../styles/AircraftDetail.module.scss'
import { AuthContext } from '../../../context/AuthProvider';
import PermissionWrapper from '../../../components/PermissionWrapper';
import Modal from '../../../components/Modal';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import toast from 'react-hot-toast';
import { User } from '../../../interfaces/User';
import { useForm } from 'react-hook-form';
import { getAircraft } from '../../../services/aircraft/getAircraft';
import { getUsers } from '../../../services/users/getUsers';
import { putAircraft } from '../../../services/aircraft/putAircraft';
import Card from '../../../components/Card';
import { useAppPrefix } from '../../../hooks/useAppPrefix';


const AircraftDetail = () => {

  const { aircraftId } = useParams<any>()

  const [aircraft, setAircraft] = useState({} as AircraftElement)

  const [visible, setVisible] = useState(false)

  const [currentIndex, setCurrentIndex] = useState(0)

  const [loading, setLoading] = useState(true)

  const { user } = useContext(AuthContext)

  const userAccess = user.access.map(view => view.view)

  const [edit, setEdit] = useState(false)

  const [notFound, setNotFound] = useState(false)

  const { location: { pathname } } = useHistory()

  const appPrefix = useAppPrefix(pathname)

  const [image, setImage] = useState('')
  const [companyLogo, setCompanyLogo] = useState('')
  const imageRef = useRef<any>()
  const logoRef = useRef<any>()

  const [saving, setSaving] = useState(false)

  const { control, register, reset, handleSubmit } = useForm()

  const [users, setUsers] = useState<User[]>([])

  const fetchAircraft = async () => {
    try {
      const data = await getAircraft(aircraftId)
      setAircraft(data.aircraft)
      setImage(data.aircraft.image)
      setCompanyLogo(data.aircraft.companyLogo || '')
      setLoading(false)
    } catch (error: any) {
      if (error.response.status === 404) {
        setNotFound(true)
        setLoading(false)
      }
    }
  }

  const fetchUsers = async () => {
    const data = await getUsers()
    setUsers(data.users)
  }

  useEffect(() => {
    fetchAircraft()
    fetchUsers()
  }, [])

  const setCurrentItem = (item: Status, index: number) => {
    reset({
      total: item.total,
      cycles: item.cycles,
      lastUpdated: moment(item.lastUpdated).format('ll'),
    })
    setVisible(true)
    setCurrentIndex(index)
  }

  const save = async (values: any) => {
    setSaving(true)
    const status = [...aircraft.status]
    status[currentIndex] = {
      itemName: status[currentIndex].itemName,
      itemId: status[currentIndex].itemId,
      total: +values.total,
      cycles: +values.cycles,
      lastUpdated: new Date()
    }

    try {
      await api.put(`/api/aircraft/${aircraft.id}`, { status })
      fetchAircraft()
      setVisible(false)
      toast.success('Succesfully updated aircraft status')
      setSaving(false)
    } catch (error: any) {
      toast.error(error.response.data.message)
      setSaving(false)
    }
  }

  const saveToS3 = async (e: any, imageType: string) => {
    const file = e.target.files[0]
    if (!file) {
      return
    }
    const formData = new FormData()
    formData.append('image', file)

    try {
      const { data } = await api.post('/api/aircraft/upload', formData)

      if (imageType === 'image') {
        setImage(data.Location)
      } else {
        setCompanyLogo(data.Location)
      }
      toast.success('Uploaded image')
    } catch (error: any) {
      toast.error(error.response.data.message)
    }

  }

  const onSubmit = async (values: any) => {
    setSaving(true)
    const update = {
      ...values,
      analyst: values.analyst.value,
      operator: values.operator.value
    }
    try {
      await putAircraft(aircraft.id, update)
      toast.success('Sucesfully edited aircraft')
      setSaving(false)
      setEdit(false)
      fetchAircraft()
    } catch (error: any) {
      toast.error(error.response.data.message)
      setSaving(false)
    }
  }

  if (loading) return <div className="empty"><div className="loader"></div></div>

  return (
    <>
      {
        notFound ?
          <div className="empty">
            <div className="icon">
              <span className="material-symbols-rounded">
                error
              </span>
            </div>
            <h3>404. This page does not exist</h3>
            <Link to='/' className='btn btn-link btn-icon'>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
              </svg>
              Back to Home
            </Link>
          </div> :
          <div>
            <div className="header">
              <Link to={`/${appPrefix}/fleet`} className='btn btn-link btn-icon'>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
                </svg>
                Back to Fleet
              </Link>
            </div>
            <div className={styles.menuGrid}>
              <div className={styles.info}>
                <div className={styles.header}>
                  <h2>Aircraft Info</h2>
                  <PermissionWrapper
                    role='create-edit'
                    view='/mtx/fleet'
                  >
                    <button onClick={() => {
                      setEdit(true)
                      reset({
                        ...aircraft,
                        operator: {
                          label: aircraft.operator.name,
                          value: aircraft.operator._id
                        },
                        analyst: {
                          label: aircraft.analyst.name,
                          value: aircraft.analyst._id
                        },
                      })
                    }} className='btn btn-blue'>Edit</button>
                  </PermissionWrapper>
                </div>
                <table>
                  <tbody>
                    <tr>
                      <td><b>Reg #</b></td>
                      <td>{aircraft.registrationNumber}</td>
                    </tr>
                    <tr>
                      <td><b>Serial</b></td>
                      <td>{aircraft.serialNumber}</td>
                    </tr>
                    <tr>
                      <td><b>Model</b></td>
                      <td>{aircraft.model}</td>
                    </tr>
                    <tr>
                      <td><b>Analyst</b></td>
                      <td>{aircraft.analyst.name}</td>
                    </tr>
                    <tr>
                      <td><b>Operator</b></td>
                      <td>{aircraft.operator.name}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className={styles.status}>
                <h2>Aircraft Status</h2>
                <div className={styles.mobileStatus}>
                  <Collapse
                    destroyInactivePanel
                    bordered={false}
                    expandIconPosition='right'
                  >
                    {
                      aircraft.status.map((item: Status, index: number) => (
                        <Collapse.Panel
                          key={item.itemId}
                          header={<b>{item.itemName}</b>}
                          style={{ backgroundColor: 'white' }}
                        >
                          <div className={styles.verticalStatus}>
                            <b>Total</b>
                            <span>{item.total}</span>
                          </div>
                          <div className={styles.verticalStatus}>
                            <b>Ldg/cycles</b>
                            <span>{item.cycles}</span>
                          </div>
                          <div className={styles.verticalStatus}>
                            <b>Last updated</b>
                            <span>{moment(item.lastUpdated).format('ll')}</span>
                          </div>
                          <PermissionWrapper
                            role='create-edit'
                            view='/mtx/fleet'
                          >
                            <div className={styles.verticalStatus}>
                              <b>Update status</b>

                              <div
                                onClick={() => { setCurrentItem(item, index) }}
                              >
                                <PencilIcon style={{ width: 30 }} />
                              </div>
                            </div>
                          </PermissionWrapper>
                        </Collapse.Panel>
                      ))
                    }
                  </Collapse>
                </div>
                <div className={styles.webStatus}>
                  <table>
                    <thead>
                      <tr>
                        <th>Tracking rate</th>
                        <th>Total</th>
                        <th>Ldg/Cycles</th>
                        <th>Last updated</th>
                        <PermissionWrapper
                          role='create-edit'
                          view='/mtx/fleet'
                        >
                          <th>Update status</th>
                        </PermissionWrapper>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        aircraft.status.map((item: Status, index: number) => (
                          <tr key={item.itemName}>
                            <td>{item.itemName}</td>
                            <td>{item.total}</td>
                            <td>{item.cycles}</td>
                            <td>{moment(item.lastUpdated).format('ll')}</td>
                            <PermissionWrapper
                              role='create-edit'
                              view='/mtx/fleet'
                            >
                              <td>
                                <Tooltip title={`Update ${item.itemName}`}>
                                  <div onClick={() => { setCurrentItem(item, index) }}>
                                    <PencilIcon style={{ width: 30 }} />
                                  </div>
                                </Tooltip>
                              </td>
                            </PermissionWrapper>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </div>


              <Modal
                visible={visible}
                onCancel={() => {
                  setVisible(false)
                }}
                onClose={() => {
                  setVisible(false)
                }}
                onOk={handleSubmit(save)}
                title="Update aircraft status"
                bodyStyle={{
                  height: 'auto',
                }}
                loadingState={saving}
              >
                <div>
                  <Input
                    name='total'
                    label="Total hours"
                    placeholder='Total hours'
                    required
                    type='number'
                    register={register}
                  />
                  <Input
                    name='cycles'
                    label="Total cycles"
                    placeholder='Total cycles'
                    required
                    type='number'
                    register={register}
                  />
                  <Input
                    name='lastUpdated'
                    label="Last updated"
                    placeholder='Last updated'
                    required
                    register={register}
                    disabled
                  />
                </div>
              </Modal>
            </div>
            <div className={styles.grid}>
              {
                user.role.value === 'admin' ?
                  menuLinks.filter(link => link.apps.includes(appPrefix)).map(link => (
                    <Card
                      path={`/${appPrefix}/aircraft/${aircraft.registrationNumber}/${link.path}`}
                      icon={link.icon}
                      name={link.name}
                    />
                  )) :
                  menuLinks.filter(link => userAccess.includes(link.generalPath) && link.apps.includes(appPrefix)).map(link => (
                    <Card
                      path={`/${appPrefix}/aircraft/${aircraft.registrationNumber}/${link.path}`}
                      icon={link.icon}
                      name={link.name}
                    />
                  ))
              }
            </div>
          </div>
      }

      <Modal
        title='Edit aircraft'
        visible={edit}
        onCancel={() => {
          setEdit(false)
        }}
        onClose={() => {
          setEdit(false)
        }}
        onOk={handleSubmit(onSubmit)}
        loadingState={saving}
      >
        <form
        >
          <Input
            name='serialNumber'
            label='Serial number'
            placeholder='Serial number'
            required
            register={register}
          />
          <Input
            name='registrationNumber'
            label="Registration number"
            placeholder='Registration number'
            required
            register={register}
          />
          <Input
            name='model'
            label="Model"
            placeholder='Model'
            required
            register={register}
          />
          <Select
            control={control}
            name='operator'
            label='Operator'
            required
            options={users?.map(user => (
              {
                label: user.name,
                value: user.id
              }
            ))}
          />
          <Select
            control={control}
            name='analyst'
            label='Analyst'
            required
            options={users?.map(user => (
              {
                label: user.name,
                value: user.id
              }
            ))}
          />
          <div className='input-group'>
            <label>Aircraft image</label>
            <input
              onChange={(e) => {
                saveToS3(e, 'image')
              }}
              ref={imageRef}
              style={{ display: 'none' }}
              type='file'
              accept='image/*'
            />
            <>
              {
                image !== '' &&
                <div
                  className='preview'>
                  <img src={image} alt='' />
                  <div
                    onClick={() => {
                      setImage('')
                    }}
                    className="delete">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </div>
                </div>
              }
              {
                image === '' &&
                <div
                  className='dropzone'
                  onClick={() => { imageRef.current.click() }} >
                  <span>
                    Choose image
                  </span>
                </div>
              }
            </>
          </div>
          <div className='input-group'>
            <label>Company logo </label>
            <input
              onChange={(e) => {
                saveToS3(e, 'companyLogo')
              }}
              ref={logoRef}
              style={{ display: 'none' }}
              type='file'
              accept='image/*'
            />
            <>
              {
                companyLogo !== '' &&
                <div
                  className='preview'>
                  <img src={companyLogo} alt='' />
                  <div
                    onClick={() => {
                      setCompanyLogo('')
                    }}
                    className="delete">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </div>
                </div>
              }
              {
                companyLogo === '' &&
                <div
                  className='dropzone'
                  onClick={() => { logoRef.current.click() }} >
                  <span>
                    Choose image
                  </span>
                </div>
              }
            </>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default AircraftDetail
