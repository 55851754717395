import { Document, Font, Image, Page, Text, View } from "@react-pdf/renderer";
import { styles } from "../../../helpers/styles";

interface ReportProps {
  data: any[],
  columns: any[],
  title?: string,
  logo?: string
}

const Report = ({ data, columns, title, logo = '/global-tracking-logo.png' }: ReportProps) => {
  const Table = ({ data, columns }: any) => {
    return (
      <View style={{ display: 'flex' }}>
        <View style={[styles.row]} fixed>
          {
            columns.map((col: any, index: number) => (
              <View key={col.title} style={styles.col}>
                <Text style={styles.fontSmallBold}>{col.title}</Text>
              </View>
            ))
          }

        </View>
        <View >
          {
            data.map((item: any, index: number) => {
              return (
                <View key={index} style={[styles.row]}>
                  {
                    columns.map((col: any, index: number) => {
                      return (
                        <View key={index} style={styles.col}>
                          {
                            ('render' in col) ?
                              <View>
                                {col.render(item[col.dataIndex], item)}
                              </View> :
                              <Text style={styles.fontSmall}>{item[col.dataIndex]}</Text>
                          }
                        </View>
                      )
                    })
                  }
                </View>
              )
            }

            )
          }
        </View>
      </View>
    )
  }


  return (
    <Document>
      <Page style={styles.body}>
        <View fixed style={styles.header}>
          <View>
            <Image
              source={{
                uri: logo,
                method: "GET",
                headers: { "Cache-Control": "no-cache" }, body: ""
              }}

              style={styles.image}
            />
          </View>
          <View>
            <Text style={styles.title}>{title}</Text>
          </View>
          <View>
            <Text style={styles.title}>Global Tracking</Text>
          </View>
        </View>
        <View style={styles.spacer}>
          <Text style={styles.title}>Scheduled Flights</Text>
        </View>

        <Table data={data} columns={columns} />

        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
    </Document>
  )
};

export default Report

Font.register(
  {
    family: 'Rubik',
    src: '/Rubik-Regular.ttf',
  },
);
Font.register(
  {
    family: 'RubikBold',
    src: '/Rubik-Bold.ttf'
  }
);

