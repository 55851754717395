import { useContext, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import {
  Switch,
  useLocation
} from "react-router-dom";
import Loading from "./components/Loading";
import PrivateRoute from "./components/PrivateRoute";
import ProtectedRoutes from "./components/ProtectedRoutes";
import PublicRoute from "./components/PublicRoute";
import { AircraftProvider } from "./context/AircraftProvider";
import { AuthContext } from "./context/AuthProvider";
import { Login } from "./pages/Login";


const AppRouter = () => {

  const { user, loading } = useContext(AuthContext)

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    loading ? <Loading/> :
      <div>
        <AircraftProvider>
          <Switch>
            <PublicRoute
              path="/login"
              isAuthenticated={user.id}
            >
              <Login />
            </PublicRoute>
            <PrivateRoute
              path="/"
              isAuthenticated={user.id}
            >
              <ProtectedRoutes />
            </PrivateRoute>
          </Switch>
        </AircraftProvider>
        <Toaster />
      </div>
  )
}

export default AppRouter
