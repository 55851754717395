import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import styles from '../styles/SubSidebarNavLink.module.scss'

interface Props {
  path: string,
  icon: JSX.Element,
  name: string,
  description?: string
}

const SubSidebarNavLink: FC<Props> = ({ path, name, icon }) => {
  return (
    <NavLink
      className={styles.subSidebarNavLink}
      activeClassName={styles.subSidebarNavLinkActive}
      to={path}>
      {
        icon
      }
      <span>
        {name}
      </span>
    </NavLink>
  )
}

export default SubSidebarNavLink

