import { Tag, Table, Button, Modal, Form, Row, Col, Select, DatePicker, Input, InputNumber, message } from "antd"
import moment from "moment"
import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router"
import { api } from "../api/api"
import SubLayout from "../components/SubLayout"
import { VerticalTable } from "../components/VerticalTable"
import { AircraftContext } from "../context/AircraftProvider"
import { Status } from "../interfaces/Aircraft"
import { Task } from "../interfaces/Task"


const DirectiveDetail = () => {

  const { directiveId } = useParams<any>()
  const [edit, setEdit] = useState(false)
  const [update, setUpdate] = useState(false)
  const {aircraftElement, setAircraftElement} = useContext(AircraftContext)
  const { aircraftId } = useParams<any>()
  const [updateForm] = Form.useForm();
  const [editForm] = Form.useForm();

  useEffect(() => {
    if (!aircraftElement) {
      api.get(`/api/aircraft/${aircraftId}`).then(({ data }) => {
        setAircraftElement(data.aircraft)

      }).catch(error => console.log(error))
    }

  }, [aircraftId, setAircraftElement, aircraftElement])

  const [directive, setDirective] = useState<any>({})

  const columns = [
    {
      title: 'ATA',
      dataIndex: 'ata',
      key: 'ata',
    },
    {
      title: 'Directive description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'P/N - S/N',
      dataIndex: 'partNumberSerialNumber',
      key: 'partNumberSerialNumber',
      render: (text: string, record: Task) => {
        return (
          <>
            {
              record?.partNumber && <span className='block mb-10'>P/N: {record?.partNumber.toString().replace("P/N", "")}</span>
            }

            {
              record?.serialNumber && <span className='block mb-10'>S/N: {record?.serialNumber.toString().replace("S/N", "")}</span>
            }
          </>
        )
      }
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
    },
    {
      title: 'Interval',
      dataIndex: 'interval',
      key: 'interval',
      render: (text: string, record: Task) => {
        return (
          <>
            {
              record.intervalType === 'hour' ?
                <span>{record.hourInterval} hours</span>
                :
                record.intervalType === 'date' ?
                  <span>{record.dateInterval} days</span>
                  :
                  record.intervalType === 'oc' ?
                    <span>O/C</span>
                    :
                    record.intervalType === 'cycle' ?
                      <span>{record.cycleInterval} cycles</span>
                      :
                      record.intervalType === 'both' ?
                        <>
                          <span>{record.dateInterval} days</span>
                          <span>{record.hourInterval?.toFixed(2)} hours</span>
                        </>
                        :
                        <span></span>
            }
          </>
        )
      }
    },
    {
      title: 'C/W',
      dataIndex: 'cw',
      key: 'cw',
      render: (text: string, record: Task) => <>
        {
          record.intervalType === 'hour' ?
            <>{record.cwHour}</>
            :
            record.intervalType === 'date' ?
              <>{moment(record.cwDate).format('ll')}</>
              :
              record.intervalType === 'oc' ?
                (record.cwDate ? <span>{moment(record.cwDate).format('ll')}</span> : 'N/A')
                :
                record.intervalType === 'cycle' ?
                  <span>{record.cwCycle}</span>
                  :
                  record.intervalType === 'both' ?
                    <>
                      <span>{moment(record.cwDate).format('ll')}</span>
                      <span>{record.cwHour?.toFixed(2)}</span>
                    </>
                    :
                    <span></span>

        }
      </>
    },
    {
      title: 'Due',
      dataIndex: 'due',
      key: 'due',
      render: (text: string, record: Task) => <>
        {
          record.intervalType === 'hour' ?
            <>{record.dueHour}</>
            :
            record.intervalType === 'date' ?
              <>{moment(record.dueDate).format('ll')}</>
              :
              record.intervalType === 'oc' ?
                <span>N/A</span>
                :
                record.intervalType === 'cycle' ?
                  <span>{record.dueCycle}</span>
                  :
                  record.intervalType === 'both' ?
                    <>
                      <span>{moment(record.dueDate).format('ll')}</span>
                      <span>{record.dueHour?.toFixed(2)}</span>
                    </>
                    :
                    <span></span>
        }
      </>,
    },
    {
      title: 'Remaining',
      dataIndex: 'remaining',
      key: 'remaining',
      render: (text: string, record: Task) => {
        return (
          <>
            {
              record.intervalType === 'hour' ?
                <span>{record.remaining} hours</span>
                :
                record.intervalType === 'date' ?
                  <span>{record.remaining} days</span>
                  :
                  record.intervalType === 'oc' ?
                    <span>N/A</span>
                    :
                    record.intervalType === 'cycle' ?
                      <span>{record.remaining} cycles</span>
                      :
                      record.intervalType === 'both' ?
                        <>
                          <span>{record.remaining.days} days</span>
                          <span>{record.remaining.hours.toFixed(2)} hours</span>
                        </>
                        :
                        <span></span>
            }
          </>
        )
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text: string, record: Task) => <Tag color={record.status === 'OK' ? 'green' : 'red'} >{text}</Tag>
    },
  ]

  useEffect(() => {
    api.get<any>(`/api/airworthiness-directives/${directiveId}`).then(({ data }) => {
      setDirective(data.directive)
    }).catch(error => console.log(error))
  }, [directiveId])

  const openEdit = () => {
    setEdit(true)

  }

  const closeEdit = () => {
    setEdit(false)
    editForm.resetFields()

  }
  const openUpdate = () => {
    setUpdate(true)

  }

  const closeUpdate = () => {
    setUpdate(false)
    updateForm.resetFields()

  }

  const updateDriective = () => {
    updateForm
      .validateFields()
      .then((values) => {
        const details = {
          //analyst: name,
          updatedOn: moment(new Date()).format('lll'),
          description: values.description,
          //attachments
        }
        let historyDetails = {};

        switch (directive.intervalType) {
          case 'hour':
            historyDetails = { ...details, lastCwHour: directive.cwHour }
            break;
          case 'cycle':
            historyDetails = { ...details, lastCwCycle: directive.cwCycle }
            break;
          case 'date':
            historyDetails = { ...details, lastCwDate: directive.cwDate }
            break;

          default:
            historyDetails = {
              ...details,
              lastCW: {
                lastCwHour: directive.cwHour,
                lastCwDate: directive.cwDate
              }
            };
            break;
        }

        // agregar historial

        //api.post('/history', historyDetails)

        // actualizar tarea
        let update
        switch (directive.intervalType) {
          case 'hour':
            update = {
              cwHour: values.currentHours,
            }

            break;
          case 'cycle':
            update = {
              cwCycle: values.currentCycles
            }

            break;
          case 'date':
            update = {
              cwDate: values.cw,
            }
            break;
          case 'both':
            update = {
              cwDate: values.cw,
              cwHour: values.currentHours,
            }
            break;

          default:
            break;
        }

        api.put(`/api/airworthiness-directives/${directiveId}`, {
          ...update,
          aircraft: aircraftId
        }).then(({ data }) => {
          updateForm.resetFields()
          closeUpdate()
          message.success('Directive updated')
          setDirective(data.task)
        })
      })
      .catch(() => {
      });
  }

  const editDirective = () => {
    editForm
      .validateFields().then((values) => {
        console.log(values)
        api.put(`/api/airworthiness-directives/${directiveId}`, {
          ...values,
          aircraft: aircraftId
        }).then(({ data }) => {
          editForm.resetFields()
          closeEdit()
          message.success('Directive updated')
          setDirective(data.task)
        })
      })
  }

  const content = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end'
      }}
    >
      <Button
        style={{ marginRight: 10 }}
        onClick={openUpdate}
        type='primary'>Update directive</Button>
      <Button
        style={{ marginRight: 10 }}
        onClick={openEdit}
        type='primary'>Edit directive</Button>
      <Button
        //onClick={() => { inputRef.current.click() }}
        type='primary'>Create work order</Button>
    </div>
  )



  return (
    <SubLayout content={content} title='Directive detail'>
      <div>
        <span>Directive ID: {directive?.id}</span>
        <h3>{directive.description}</h3>
        <div className="webTable">
          <Table dataSource={[directive]} columns={columns} />
        </div>
        <div className="mobileTable">
          <VerticalTable data={[directive]} columns={columns} />
        </div>


        <Modal
          visible={update}
          title={directive.description}
          centered
          onOk={updateDriective}
          onCancel={closeUpdate}
          bodyStyle={
            {
              height: '50vh',
              overflowY: 'scroll'
            }
          }
        /* footer={
          [
            <Button key="back" onClick={handleCancelUpdate}>Cancel</Button>,
            <Button loading={loadingFiles} key="submit" type="primary" onClick={handleUpdateDriective}>Update</Button>,
          ]
        } */

        >
          <Form
            layout="vertical"
            name="Update directive"
            form={updateForm}
          >

            {
              (directive.intervalType === 'both' || directive.intervalType === 'date') &&
              <Row gutter={[24, 24]}>
                <Col span={24} >
                  <Form.Item
                    name="cw"
                    label="C/W"
                    rules={[
                      {
                        required: true,
                        message: 'Required',
                      },
                    ]}
                  >
                    <DatePicker style={{ width: '50%' }} />
                  </Form.Item>
                </Col>
              </Row>
            }

            {
              (directive.intervalType === 'both' || directive.intervalType === 'hour') &&
              <Row gutter={[24, 24]}>
                <Col span={24} >
                  <Form.Item
                    name="currentHours"
                    label="Current Hours"
                    rules={[
                      {
                        required: true,
                        message: 'Required',
                      },
                    ]}
                  >
                    <InputNumber style={{ width: '50%' }}></InputNumber>
                  </Form.Item>
                </Col>
              </Row>
            }

            {
              directive.intervalType === 'cycle' &&
              <Row gutter={[24, 24]}>
                <Col span={24} >
                  <Form.Item
                    name="currentCycles"
                    label="Current Cycles"
                    rules={[
                      {
                        required: true,
                        message: 'Required',
                      },
                    ]}
                  >
                    <InputNumber style={{ width: '50%' }}></InputNumber>
                  </Form.Item>
                </Col>
              </Row>
            }



            <Row gutter={[24, 24]}>
              <Col span={24} >
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    {
                      required: true,
                      message: 'Required',
                    },
                  ]}
                >
                  <Input.TextArea style={{ resize: 'none' }} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[24, 24]}>
              <Col span={24} >
                <Form.Item
                  name="attachments"
                /* rules={[
                  {
                    required: true,
                    message: 'Required',
                  },
                ]} */
                >
                  {/* <div>
                    {
                      attachments.length > 0 &&
                      attachments.map((attachment) => {
                        return (
                          <FilePreview attachment={attachment} setAttachments={setAttachments} attachments={attachments} />
                        )
                      })
                    }
                  </div> */}
                  <div style={{ display: 'none' }}>
                    <Input />
                  </div>
                  {/* <Button
                    loading={loadingFiles}
                    icon={<CloudUploadOutlined style={{ fontSize: 20, verticalAlign: 'middle' }} />}
                    onClick={handleInputClick}
                    type="primary" block>{loadingFiles ? "Uploading..." : attachments.length > 0 ? "Add more attachments" : "Add attachments"}</Button>
                  <input
                    multiple
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                    type="file"
                    id="fileInput" /> */}
                </Form.Item>
              </Col>
            </Row>

          </Form>

        </Modal>

        <Modal
          visible={edit}
          title="Edit directive"
          okText="Save"
          cancelText="Cancel"
          onCancel={closeEdit}
          centered
          onOk={editDirective}
          width='80%'
          bodyStyle={{
            height: '60vh',
            overflowY: 'scroll'
          }}
        >
          <Form
            form={editForm}
            layout="vertical"
            name="Edit directive"
            initialValues={{
              partNumber: directive.partNumber,
              serialNumber: directive.serialNumber,
              description: directive.description,
              intervalType: directive.intervalType,
              hourInterval: directive.hourInterval,
              dateInterval: directive.dateInterval,
              cycleInterval: directive.cycleInterval,
              appliesTo: directive.appliesTo,
              ata: directive.ata,
              cwHour: directive.cwHour,
              cwCycle: directive.cwCycle,
            }}
          >
            <Row gutter={[{ xs: 8, sm: 12, md: 24 }, { xs: 4, sm: 12, md: 8 }]}>
              <Col xs={24} sm={12} lg={6}>
                <Form.Item
                  name="partNumber"
                  label="Part number"

                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={6} >
                <Form.Item
                  name="serialNumber"
                  label="Serial Number"

                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} >
                <Form.Item
                  name="description"
                  label="Directive description"
                  rules={[
                    {
                      required: true,
                      message: 'Required',
                    },
                  ]}
                >
                  <Input.TextArea style={{ resize: 'none' }} />
                </Form.Item>
              </Col>
            </Row>


            <Row gutter={[{ xs: 8, sm: 12, md: 24 }, { xs: 4, sm: 12, md: 8 }]}>

              <Col xs={24} sm={12} lg={6} >
                <Form.Item
                  name="intervalType"
                  label="Interval Type"
                  rules={[
                    {
                      required: true,
                      message: 'Required',
                    },
                  ]}
                >
                  <Select>
                    <Select.Option key='oc' value='oc'>
                      O/C
                    </Select.Option>
                    <Select.Option key='hour' value='hour'>
                      Hour
                    </Select.Option>
                    <Select.Option key='date' value='date'>
                      Date
                    </Select.Option>
                    <Select.Option key='cycle' value='cycle'>
                      Cycle
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={6}>
                <Form.Item
                  name="hourInterval"
                  label="Hour Interval"

                >
                  <InputNumber />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={6}>
                <Form.Item
                  name="dateInterval"
                  label="Date interval"

                >
                  <InputNumber />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={6}>
                <Form.Item
                  name="cycleInterval"
                  label="Cycle Interval"

                >
                  <InputNumber />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[{ xs: 8, sm: 12, md: 24 }, { xs: 4, sm: 12, md: 8 }]}>
              <Col xs={24} sm={12} lg={6}>
                <Form.Item
                  name="ata"
                  label="ATA"
                  rules={[
                    {
                      required: true,
                      message: 'Required',
                    },
                  ]}
                >
                  <InputNumber />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={6}>
                <Form.Item
                  name="cwDate"
                  label="CW date"

                >
                  <DatePicker />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={6}>
                <Form.Item
                  name="cwHour"
                  label="CW hour"

                >
                  <InputNumber />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} lg={6}>
                <Form.Item
                  name="cwCycle"
                  label="CW cycle"

                >
                  <InputNumber />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[{ xs: 8, sm: 12, md: 24 }, { xs: 4, sm: 12, md: 8 }]}>
              <Col xs={24} sm={12} lg={6}>
                <Form.Item
                  name="appliesTo"
                  label="Applies To"
                  rules={[
                    {
                      required: true,
                      message: 'Required',
                    },
                  ]}
                >
                  <Select>
                    {
                      aircraftElement?.status.map((item: Status) =>
                        <Select.Option key={item.itemId} value={item.itemId}>
                          {item.itemName}
                        </Select.Option>
                      )
                    }
                  </Select>
                </Form.Item>
              </Col>

            </Row>
          </Form>
        </Modal>
      </div>
    </SubLayout>
  )
}

export default DirectiveDetail
