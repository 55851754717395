import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './styles/Globals.scss';
import "react-datepicker/dist/react-datepicker.css";
import 'rc-time-picker/assets/index.css';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('app')
);
