import { Text } from "@react-pdf/renderer";
import moment from "moment";
import { styles } from "./styles";

export const discrepancyColumns = [
  {
    title: 'Number',
    dataIndex: 'number',
    key: 'number',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    //render: (text) => <Tag color={text === 'Completed' ? 'green' : 'error'}>{text}</Tag>,
  },
  {
    title: 'Man hours',
    dataIndex: 'manHours',
    key: 'manHours',
  },
  {
    title: 'Created date',
    dataIndex: 'createdDate',
    key: 'createdDate',
    render: (text: string) => <Text style={styles.fontSmall}>{moment(text).format('lll')}</Text>,
  },

]