import AppRouter from "./AppRouter";
import {
  BrowserRouter as Router
} from "react-router-dom";
import { AuthProvider } from "./context/AuthProvider";
import { Toaster } from 'react-hot-toast'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()

function App() {
  return (
    <>
      <Toaster />
      <Router>
        <AuthProvider>
          <QueryClientProvider client={queryClient}>
            <AppRouter />
          </QueryClientProvider>
        </AuthProvider>
      </Router>
    </>
  );
}

export default App;
