import React, { CSSProperties, FC } from 'react'
import { FieldValues, UseFormRegister } from 'react-hook-form'

interface Props {
  register?: UseFormRegister<FieldValues>,
  name: string,
  label: string,
  value?: any
  id: string
  onChange?: any
  style?: CSSProperties
  defaultChecked?: boolean
}

const Checkbox: FC<Props> = ({ register, defaultChecked, name, label, value, id, onChange, style }) => {
  return (
    <div
      style={style}
      className='checkbox'>
      <input
        defaultChecked={defaultChecked}
        {
        ...register && { ...register(name) }
        }
        type="checkbox"
        id={id}
        value={value}
        onChange={onChange}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  )
}

export default Checkbox