import React, { useContext } from 'react'
import { AuthContext } from '../context/AuthProvider'
import HomeAdmin from '../pages/HomeAdmin'
import HomeUser from '../pages/HomeUser'

const Home = () => {

  const { user } = useContext(AuthContext)

  return (
    user.role.value==='admin' ? <HomeAdmin /> : <HomeUser />
  )
}

export default Home