import React, { FC } from 'react'
import { Control, Controller, FieldValues } from 'react-hook-form'
import ReactTimePicker from 'rc-time-picker'

interface Props {
  control?: Control<FieldValues, any>,
  name: string,
  errors?: {
    [x: string]: any;
  },
  label: string,
  required?: boolean,
  placeholder:string
}

export const TimePicker: FC<Props> = ({ name, label, required, control, errors, placeholder }) => {
  return (
    <div className="input-group">
      <label>{label}</label>
      <Controller
        rules={{
          required: {
            value: required || false,
            message: 'Required'
          }
        }}
        name={name}
        control={control}
        render={({ field: { onChange, onBlur, value } }) =>
          <ReactTimePicker
            placeholder={placeholder}
            value={value}
            showSecond={false}
            showMinute={true}
            onChange={onChange}
            format='h:mm a'
            minuteStep={15}
            use12Hours
            inputReadOnly
          />}
      />
      {
        (errors && errors[name]) &&
        <span className='error'>{errors[name].message}</span>
      }
    </div>
  )
}
