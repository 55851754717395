import { Tag, Button, Form, Row, Col, Menu } from "antd"
import moment from "moment"
import { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { toast } from "react-hot-toast"
import { useHistory, useParams } from "react-router"
import { api } from "../api/api"
import DatePicker from "../components/DatePicker"
import Input from "../components/Input"
import Loading from "../components/Loading"
import Modal from "../components/Modal"
import Select from "../components/Select"
import SubLayout from "../components/SubLayout"
import Table from "../components/Table"
import TextArea from "../components/TextArea"
import { AircraftContext } from "../context/AircraftProvider"
import { Task } from "../interfaces/Task"
import { putTask } from "../services/tasks"
import { getTask } from "../services/tasks/getTask"

const TaskDetail = () => {

  const { taskId, aircraftId } = useParams<any>()
  const [edit, setEdit] = useState(false)
  const [update, setUpdate] = useState(false)
  const { aircraftElement, setAircraftElement } = useContext(AircraftContext)
  const [deleteConfirm, setDeleteConfirm] = useState(false)
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [task, setTask] = useState({} as Task)

  const [intervalType, setIntervalType] = useState<any>(null)

  const { control, register, reset, handleSubmit, formState: { errors } } = useForm()


  const { control: updateControl, register: registerUpdate, reset: resetUpdate, handleSubmit: handleSubmitUpdate, formState: { errors: updateErrors } } = useForm()

  const [departureDate, setDepartureDate] = useState(new Date())

  const params = useParams()

  console.log({params})

  useEffect(() => {
    if (!aircraftElement) {
      api.get(`/api/aircraft/${aircraftId}`).then(({ data }) => {
        setAircraftElement(data.aircraft)
      }).catch(error => console.log(error))
    }
  }, [aircraftId, setAircraftElement, aircraftElement])

  const columns = [
    {
      title: 'ATA',
      dataIndex: 'ata',
      key: 'ata',
    },
    {
      title: 'Task description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'P/N - S/N',
      dataIndex: 'partNumberSerialNumber',
      key: 'partNumberSerialNumber',
      render: (text: string, record: Task) => {
        return (
          <>
            {
              record?.partNumber && <span className='block mb-10'>P/N: {record?.partNumber.toString().replace("P/N", "")}</span>
            }

            {
              record?.serialNumber && <span className='block mb-10'>S/N: {record?.serialNumber.toString().replace("S/N", "")}</span>
            }
          </>
        )
      }
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
    },
    {
      title: 'Interval',
      dataIndex: 'interval',
      key: 'interval',
      render: (text: string, record: Task) => {
        return (
          <>
            {
              record.intervalType === 'hour' ?
                <span>{record.hourInterval} hours</span>
                :
                record.intervalType === 'date' ?
                  <span>{record.dateInterval} days</span>
                  :
                  record.intervalType === 'oc' ?
                    <span>O/C</span>
                    :
                    record.intervalType === 'cycle' ?
                      <span>{record.cycleInterval} cycles</span>
                      :
                      record.intervalType === 'both' ?
                        <>
                          <span className='block mb-10'>{record.dateInterval} days</span>
                          <span className='block mb-10'>{record.hourInterval?.toFixed(2)} hours</span>
                        </>
                        :
                        <span></span>
            }
          </>
        )
      }
    },
    {
      title: 'C/W',
      dataIndex: 'cw',
      key: 'cw',
      render: (text: string, record: Task) => <>
        {
          record.intervalType === 'hour' ?
            <>{record.cwHour}</>
            :
            record.intervalType === 'date' ?
              <>{moment(record.cwDate).format('ll')}</>
              :
              record.intervalType === 'oc' ?
                (record.cwDate ? <span>{moment(record.cwDate).format('ll')}</span> : 'N/A')
                :
                record.intervalType === 'cycle' ?
                  <span>{record.cwCycle}</span>
                  :
                  record.intervalType === 'both' ?
                    <>
                      <span className='block mb-10'>{moment(record.cwDate).format('ll')}</span>
                      <span className='block mb-10'>{record.cwHour?.toFixed(2)}</span>
                    </>
                    :
                    <span></span>

        }
      </>
    },
    {
      title: 'Due',
      dataIndex: 'due',
      key: 'due',
      render: (text: string, record: Task) => <>
        {
          record.intervalType === 'hour' ?
            <>{record.dueHour}</>
            :
            record.intervalType === 'date' ?
              <>{moment(record.dueDate).format('ll')}</>
              :
              record.intervalType === 'oc' ?
                <span>N/A</span>
                :
                record.intervalType === 'cycle' ?
                  <span>{record.dueCycle}</span>
                  :
                  record.intervalType === 'both' ?
                    <>
                      <span className='block mb-10'>{moment(record.dueDate).format('ll')}</span>
                      <span className='block mb-10'>{record.dueHour?.toFixed(2)}</span>
                    </>
                    :
                    <span></span>
        }
      </>,
    },
    {
      title: 'Remaining',
      dataIndex: 'remaining',
      key: 'remaining',
      render: (text: string, record: Task) => {
        return (
          <>
            {
              record.intervalType === 'hour' ?
                <span>{record.remaining} hours</span>
                :
                record.intervalType === 'date' ?
                  <span>{record.remaining} days</span>
                  :
                  record.intervalType === 'oc' ?
                    <span>N/A</span>
                    :
                    record.intervalType === 'cycle' ?
                      <span>{record.remaining} cycles</span>
                      :
                      record.intervalType === 'both' ?
                        <>
                          <span className='block mb-10'>{record.remaining.days} days</span>
                          <span className='block mb-10'>{record.remaining.hours.toFixed(2)} hours</span>
                        </>
                        :
                        <span></span>
            }
          </>
        )
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text: string, record: Task) => <Tag color={record.status === 'OK' ? 'green' : 'red'} >{text}</Tag>
    },
  ]

  useEffect(() => {
    fetchTask()
  }, [])


  const fetchTask = async () => {
    try {
      const data = await getTask(taskId)
      setTask(data.task)
      setLoading(false)
      reset({
        ...data.task,
        intervalType: {
          label: data.task.intervalType,
          value: data.task.intervalType
        },
        appliesTo: {
          label: data.task.appliesTo,
          value: data.task.appliesTo
        }
      })
      setIntervalType(data.task.intervalType)
    } catch (error) {
      setLoading(false)
    }
  }

  const openEdit = () => {
    setEdit(true)
  }

  const closeEdit = () => {
    setEdit(false)
  }
  const openUpdate = () => {
    setUpdate(true)

  }

  const closeUpdate = () => {
    setUpdate(false)
  }

  const updateTask = async (values: any) => {

    const details = {
      //analyst: name,
      updatedOn: moment(new Date()).format('lll'),
      description: values.description,
      //attachments
    }
    let historyDetails = {};

    switch (task.intervalType) {
      case 'hour':
        historyDetails = {
          ...details,
          lastCwHour: task.cwHour
        }
        break;
      case 'cycle':
        historyDetails = {
          ...details,
          lastCwCycle: task.cwCycle
        }
        break;
      case 'date':
        historyDetails = {
          ...details,
          lastCwDate: task.cwDate
        }
        break;

      default:
        historyDetails = {
          ...details,
          lastCW: {
            lastCwHour: task.cwHour,
            lastCwDate: task.cwDate
          }
        };
        break;
    }

    // agregar historial

    //api.post('/history', historyDetails)

    // actualizar tarea
    let update
    switch (task.intervalType) {
      case 'hour':
        update = {
          cwHour: values.currentHours,
        }
        break;
      case 'cycle':
        update = {
          cwCycle: values.currentCycles
        }
        break;
      case 'date':
        update = {
          cwDate: values.cw,
        }
        break;
      case 'both':
        update = {
          cwDate: values.cw,
          cwHour: values.currentHours,
        }
        break;

      default:
        break;
    }

    try {
      await api.put(`/api/tasks/${taskId}`, {
        ...update,
        aircraft: aircraftId
      })
      fetchTask()
      toast.success('Task updated')
    } catch (error: any) {
      toast.error(error.response.data.message)
    }
  }

  const editTask = async (values: any) => {
    const update = {
      ...values,
      intervalType: values.intervalType.value,
      appliesTo: values.appliesTo.value,
      aircraft: aircraftId,
    }
    console.log({update})
    try {
      await putTask(update, task.id)
      setEdit(false)
      toast.success("Task updated")
      fetchTask()
    } catch (error: any) {
      toast.error(error.response.data.message)
    }
  }

  const content = (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end'
      }}
    >
      <button
        style={{ marginRight: 10 }}
        onClick={openUpdate}
        className='btn btn-blue'
      >
        Update task
      </button>
      <button
        style={{ marginRight: 10 }}
        onClick={openEdit}
        className='btn btn-blue'
      >
        Edit task
      </button>
      <button
        style={{ marginRight: 10 }}
        className='btn btn-blue'
      >
        Create work order
      </button>
      <button
        onClick={() => setDeleteConfirm(true)}
        className='btn btn-red'>Delete task</button>
    </div>
  )

  const menu = (
    <Menu>
      <Menu.Item
        onClick={openUpdate}
        key='Update task'>
        Update task
      </Menu.Item>
      <Menu.Item
        onClick={openEdit}
        key='Edit task'>
        Edit task
      </Menu.Item>
      <Menu.Item
        key='Create work order'>
        Create work order
      </Menu.Item>
      <Menu.Item
        onClick={() => setDeleteConfirm(true)}
        key='Delete task'>
        Delete task
      </Menu.Item>
    </Menu>
  );

  const deleteTask = async () => {

    try {
      //call api DEL 
      await api.delete(`/api/tasks/${task.id}`)
      toast.success('Succesfully deleted task')
      setDeleteConfirm(false)
      history.replace(`/mtx/aircraft/${aircraftId}/tasks`)
    } catch (error: any) {
      toast.error(error.response.data.message)
    }
  }

  if (loading) return <Loading />

  return (
    <SubLayout menu={menu} content={content} title='Task detail'>
      <div>
        <span className='mb-20 block'>Task ID: {task.id}</span>
        <h3 className='mb-20'>{task.description}</h3>
        <Table
          data={[task]}
          columns={columns}
        />
        <Modal
          bodyStyle={{
            height: 'fit-content'
          }}
          onOk={deleteTask}
          visible={deleteConfirm}
          title='Delete task'
          onCancel={() => {
            setDeleteConfirm(false)
          }}
          onClose={() => {
            setDeleteConfirm(false)
          }}
        >
          <h3 style={{ textAlign: 'center' }}>Are you sure to delete this task?</h3>
        </Modal>

        <Modal
          visible={update}
          title={task.description}
          onOk={handleSubmitUpdate(updateTask)}
          onCancel={() => {
            setUpdate(false)
          }}
          onClose={() => {
            setUpdate(false)
          }}
          bodyStyle={
            {
              height: '50vh',
              overflowY: 'scroll'
            }
          }
        >
          <div>
            {
              (task.intervalType === 'both' || task.intervalType === 'date') &&
              <DatePicker
                name='cw'
                label='CW'
                required
                selectedDate={departureDate}
                setSelectedDate={setDepartureDate}
                control={updateControl}
                minDate={undefined}
              />
            }

            {
              (task.intervalType === 'both' || task.intervalType === 'hour') &&
              <Input
                name='currentHours'
                label='Current Hours'
                placeholder='Current Hours'
                errors={updateErrors}
                required
                register={registerUpdate}
              />
            }
            {
              task.intervalType === 'cycle' &&
              <Input
                name='currentCycles'
                label='Current Cycles'
                placeholder='Current Cycles'
                errors={updateErrors}
                register={registerUpdate}
                required
              />
            }
            <TextArea
              name='description'
              label='Description'
              placeholder='Description'
              required
              errors={updateErrors}
              register={registerUpdate}
            />
            <Row gutter={[24, 24]}>
              <Col span={24} >
                <Form.Item
                  name="attachments"
                /* rules={[
                  {
                    required: true,
                    message: 'Required',
                  },
                ]} */
                >
                  {/* <div>
                    {
                      attachments.length > 0 &&
                      attachments.map((attachment) => {
                        return (
                          <FilePreview attachment={attachment} setAttachments={setAttachments} attachments={attachments} />
                        )
                      })
                    }
                  </div> */}
                  {/* <div style={{ display: 'none' }}>
                    <Input />
                  </div> */}
                  {/* <Button
                    loading={loadingFiles}
                    icon={<CloudUploadOutlined style={{ fontSize: 20, verticalAlign: 'middle' }} />}
                    onClick={handleInputClick}
                    type="primary" block>{loadingFiles ? "Uploading..." : attachments.length > 0 ? "Add more attachments" : "Add attachments"}</Button>
                  <input
                    multiple
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                    type="file"
                    id="fileInput" /> */}
                </Form.Item>
              </Col>
            </Row>

          </div>

        </Modal>

        <Modal
          visible={edit}
          title="Edit task"
          onCancel={closeEdit}
          onClose={closeEdit}
          onOk={handleSubmit(editTask)}
        >
          <div
          >
            <Input
              name='partNumber'
              label='Part Number'
              placeholder='Part Number'
              errors={errors}
              register={register}
            />
            <Input
              name='serialNumber'
              label='Serial number'
              placeholder='Serial number'
              errors={errors}
              register={register}
            />
            <TextArea
              name='description'
              label='Description'
              placeholder='Description'
              required
              errors={errors}
              register={register}
            />
            <TextArea
              name='notes'
              label='Notes'
              placeholder='Notes'
              register={register}
            />
            <Select
              name='intervalType'
              label='Interval type'
              required
              errors={errors}
              control={control}
              options={[
                {
                  label: 'O/C',
                  value: 'oc'
                },
                {
                  label: 'Hour',
                  value: 'hour'
                },
                {
                  label: 'Date',
                  value: 'date'
                },
                {
                  label: 'Cycle',
                  value: 'cycle'
                },
                {
                  label: 'Both',
                  value: 'both'
                },
              ]}
              handleChange={(e: any) => {
                setIntervalType(e.value);
              }}
            />
            {
              (intervalType === 'hour' || intervalType === 'both') &&
              <Input
                name='hourInterval'
                label='Hour Interval'
                placeholder='Hour Interval'
                required
                errors={errors}
                register={register}
              />
            }
            {
              (intervalType === 'date' || intervalType === 'both') &&
              <Input
                name='dateInterval'
                label='Date Interval'
                placeholder='Date Interval'
                required
                errors={errors}
                register={register}
              />
            }
            {
              intervalType === 'cycle' &&
              <Input
                name='cycleInterval'
                label='Cycle Interval'
                placeholder='Cycle Interval'
                required
                errors={errors}
                register={register}
              />
            }
            <Input
              name='ata'
              label='ATA'
              placeholder='ATA'
              required
              errors={errors}
              register={register}
            />
            {
              (intervalType === 'date' || intervalType === 'both' || intervalType === 'oc') &&
              <DatePicker
                name='cwDate'
                label='CW Date'
                required
                selectedDate={departureDate}
                setSelectedDate={setDepartureDate}
                control={control}
                minDate={undefined}
              />
            }
            {
              (intervalType === 'hour' || intervalType === 'both' || intervalType === 'oc') &&
              <Input
                name='cwHour'
                label='CW Hour'
                placeholder='CW Hour'
                required
                errors={errors}
                register={register}
              />
            }
            {
              intervalType === 'cycle' &&
              <Input
                name='cwCycle'
                label='CW Cycle'
                placeholder='CW Cycle'
                required
                errors={errors}
                register={register}
              />
            }
            <Select
              name='appliesTo'
              label='Applies To'
              required
              errors={errors}
              control={control}
              options={
                aircraftElement.status?.map((item: any) =>
                ({
                  label: item.itemName,
                  value: item.itemId
                })
                )
              }
            />
          </div>
        </Modal>
      </div>
    </SubLayout>
  )
}

export default TaskDetail
