import { api } from '../api/api'
import moment from 'moment'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import SubLayout from '../components/SubLayout'
import { AircraftContext } from '../context/AircraftProvider'
import { Task } from '../interfaces/Task'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { reportColumns } from '../helpers/reportColumns'
import { Report } from '../components/Report'
import PermissionWrapper from '../components/PermissionWrapper'
import { useForm } from 'react-hook-form'
import { getTasks } from '../services/tasks/getTasks'
import toast from 'react-hot-toast'
import Loading from '../components/Loading'
import Table from '../components/Table'
import Modal from '../components/Modal'
import Input from '../components/Input'
import TextArea from '../components/TextArea'
import Select from '../components/Select'
import { postTask } from '../services/tasks/postTask'
import { Menu, Tag } from 'antd'


const DueList = () => {

  const { aircraftElement, setAircraftElement } = useContext(AircraftContext)

  const { aircraftId } = useParams<any>()

  const [tasks, setTasks] = useState<Task[]>([])
  const [tempTasks, setTempTasks] = useState<Task[]>([])

  const [intervalType, setIntervalType] = useState<any>(null)
  const [doc, setDoc] = useState(false)

  const { control, register, reset, handleSubmit, formState: { errors } } = useForm()

  const [visible, setVisible] = useState(false)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!aircraftElement) {
      api.get(`/api/aircraft/${aircraftId}`).then(({ data }) => {
        setAircraftElement(data.aircraft)

      }).catch(error => console.log(error))
    }

  }, [aircraftId, setAircraftElement, aircraftElement])

  const fetchTasks = useCallback(async () => {
    try {
      const data = await getTasks(aircraftId)
      setTasks(data.tasks)
      setTempTasks(data.tasks)
      setLoading(false)
    } catch (error: any) {
      toast.error(error.response.data.message)
      setLoading(false)
    }
  }, [aircraftId])

  useEffect(() => {
    fetchTasks()
  }, [fetchTasks])

  const search = (e: any) => {
    setTasks(tempTasks.filter(task => task.description.toLowerCase().includes(e.target.value.toLowerCase())))
  }

  const showDue = () => {
    setDue(true)
    setTasks(tempTasks.filter(task => task.status === 'DUE'))

  }

  const showAll = () => {
    setDue(false)
    setTasks(tempTasks)
  }

  const [due, setDue] = useState(false)

  const menu = (
    <Menu>
      <Menu.Item
        //onClick={openModal}
        key='Create task'>
        Create task
      </Menu.Item>
      {
        doc ?
          <PDFDownloadLink
            document={<Report
              logo={aircraftElement.companyLogo}
              columns={reportColumns}
              aircraft={aircraftElement}
              title='Status report'
              data={tasks}
            />
            }
            fileName={`Status_report.pdf`}>
            {({ loading }) =>
              loading ? 'Loading document...' : <Menu.Item >Download report</Menu.Item>
            }
          </PDFDownloadLink>
          :

          <Menu.Item
            onClick={() => { setDoc(true) }}
          >
            Generate report
          </Menu.Item>
      }
      {
        due ?
          <Menu.Item
            onClick={showAll}
          >
            Show all
          </Menu.Item>
          :
          <Menu.Item
            onClick={showDue}
          >
            Show due
          </Menu.Item>
      }
    </Menu>

  );

  const content = (
    <div
      style={{ display: 'flex', justifyContent: 'space-between' }}
    >
      <div style={{
        marginRight: 10,
        flex: 1
      }}>
        <input
          onChange={search}
          className='input'
          placeholder='Search'
        />
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        {
          due ?
            <button
              className='btn btn-blue'
              style={{ marginRight: 10 }}
              onClick={showAll}
            >
              Show all
            </button>
            :
            <button
              className='btn btn-blue'
              style={{ marginRight: 10 }}
              onClick={showDue}
            >
              Show due
            </button>
        }
        <PermissionWrapper
          view='/mtx/tasks'
          role='create-edit'
        >
          <button
            className='btn btn-blue'
            style={{ marginRight: 10 }}
            //onClick={openModal}
          >
            New task
          </button>
        </PermissionWrapper>

        {
          doc ?

            <PDFDownloadLink
              document={<Report
                logo={aircraftElement.companyLogo}
                columns={reportColumns}
                aircraft={aircraftElement}
                title='Status report'
                data={tasks}
              />
              }
              fileName={`Status_report.pdf`}>
              {({ blob, url, loading, error }) =>
                loading ? 'Loading document...' : <button className='btn btn-blue'>Download report</button>
              }
            </PDFDownloadLink>
            :
            <button
              className='btn btn-blue'
              onClick={() => { setDoc(true) }}
            >
              Generate report
            </button>
        }
      </div>
    </div>
  )

  const mobileSearch = (
    <input
      className='input'
      onChange={search}
      placeholder='Search tasks'
    />
  )

  const columns = [
    {
      title: 'ATA',
      dataIndex: 'ata',
      key: 'ata',
    },
    {
      title: 'Task description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'P/N - S/N',
      dataIndex: 'partNumberSerialNumber',
      key: 'partNumberSerialNumber',
      render: (text: string, record: Task) => {
        return (
          <>
            {
              record?.partNumber && <span className='block mb-10'>P/N: {record?.partNumber.toString().replace("P/N", "")}</span>
            }

            {
              record?.serialNumber && <span className='block mb-10'>S/N: {record?.serialNumber.toString().replace("S/N", "")}</span>
            }
          </>
        )
      }
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
    },
    {
      title: 'Interval',
      dataIndex: 'interval',
      key: 'interval',
      render: (text: string, record: Task) => {
        return (
          <>
            {
              record.intervalType === 'hour' ?
                <span>{record.hourInterval} hours</span>
                :
                record.intervalType === 'date' ?
                  <span>{record.dateInterval} days</span>
                  :
                  record.intervalType === 'oc' ?
                    <span>O/C</span>
                    :
                    record.intervalType === 'cycle' ?
                      <span>{record.cycleInterval} cycles</span>
                      :
                      record.intervalType === 'both' ?
                        <>
                          <span className='block mb-10'>{record.dateInterval} days</span>
                          <span className='block mb-10'>{record.hourInterval?.toFixed(2)} hours</span>
                        </>
                        :
                        <span></span>
            }
          </>
        )
      }
    },
    {
      title: 'C/W',
      dataIndex: 'cw',
      key: 'cw',
      render: (text: string, record: Task) => <>
        {
          record.intervalType === 'hour' ?
            <>{record.cwHour}</>
            :
            record.intervalType === 'date' ?
              <>{moment(record.cwDate).format('ll')}</>
              :
              record.intervalType === 'oc' ?
                (record.cwDate ? <span>{moment(record.cwDate).format('ll')}</span> : 'N/A')
                :
                record.intervalType === 'cycle' ?
                  <span>{record.cwCycle}</span>
                  :
                  record.intervalType === 'both' ?
                    <>
                      <span className='block mb-10'>{moment(record.cwDate).format('ll')}</span>
                      <span className='block mb-10'>{record.cwHour?.toFixed(2)}</span>
                    </>
                    :
                    <span></span>

        }
      </>
    },
    {
      title: 'Due',
      dataIndex: 'due',
      key: 'due',
      render: (text: string, record: Task) => <>
        {
          record.intervalType === 'hour' ?
            <>{record.dueHour}</>
            :
            record.intervalType === 'date' ?
              <>{moment(record.dueDate).format('ll')}</>
              :
              record.intervalType === 'oc' ?
                <span>N/A</span>
                :
                record.intervalType === 'cycle' ?
                  <span>{record.dueCycle}</span>
                  :
                  record.intervalType === 'both' ?
                    <>
                      <span className='block mb-10'>{moment(record.dueDate).format('ll')}</span>
                      <span className='block mb-10'>{record.dueHour?.toFixed(2)}</span>
                    </>
                    :
                    <span></span>
        }
      </>,
    },
    {
      title: 'Remaining',
      dataIndex: 'remaining',
      key: 'remaining',
      render: (text: string, record: Task) => {
        return (
          <>
            {
              record.intervalType === 'hour' ?
                <span>{record.remaining.toFixed(2)} hours</span>
                :
                record.intervalType === 'date' ?
                  <span>{record.remaining} days</span>
                  :
                  record.intervalType === 'oc' ?
                    <span>N/A</span>
                    :
                    record.intervalType === 'cycle' ?
                      <span>{record.remaining} cycles</span>
                      :
                      record.intervalType === 'both' ?
                        <>
                          <span className='block mb-10'>{record.remaining.days} days</span>
                          <span className='block mb-10'>{record.remaining.hours.toFixed(2)} hours</span>
                        </>
                        :
                        <span></span>
            }
          </>
        )
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text: string, record: Task) => <Tag color={record.status === 'OK' ? 'green' : 'red'} >{text}</Tag>
    },
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      render: (text: string, record: Task) => <Link className='btn btn-blue' to={`/mtx/aircraft/${aircraftId}/tasks/${record.id}`}>View</Link>
    },
  ]

  const createTask = async (values: any) => {
    const task = {
      ...values,
      aircraft: aircraftId,
      appliesTo: values.appliesTo.value,
      intervalType: values.intervalType.value,
    }
    try {
      await postTask(task)
      toast.success('Task created')
      fetchTasks()
      setVisible(false)
      reset()
    } catch (error: any) {
      toast.error(error.response.data.message)
    }
  }

  return (
    <SubLayout mobileSearch={mobileSearch} content={content} title='Due list' menu={menu}>
      <div className='tasks'>
        {
          loading && <Loading />
        }
        {
          !loading &&
          <div className='tasks'>
            <Table
              data={tasks}
              columns={columns}
            />
          </div>
        }
        <Modal
          visible={visible}
          title="New task"
          onCancel={() => {
            setVisible(false)
            reset()
          }}
          onClose={() => {
            setVisible(false)
            reset()
          }}
          onOk={handleSubmit(createTask)}
        >
          <div
          >
            <Input
              name='partNumber'
              label='Part Number'
              placeholder='Part Number'
              errors={errors}
              register={register}
            />
            <Input
              name='serialNumber'
              label='Serial number'
              placeholder='Serial number'
              errors={errors}
              register={register}
            />
            <TextArea
              name='description'
              label='Description'
              placeholder='Description'
              required
              errors={errors}
              register={register}
            />
            <TextArea
              name='notes'
              label='Notes'
              placeholder='Notes'
              register={register}
            />
            <Select
              name='intervalType'
              label='Interval type'
              required
              errors={errors}
              control={control}
              options={[
                {
                  label: 'O/C',
                  value: 'oc'
                },
                {
                  label: 'Hour',
                  value: 'hour'
                },
                {
                  label: 'Date',
                  value: 'date'
                },
                {
                  label: 'Cycle',
                  value: 'cycle'
                },
                {
                  label: 'Both',
                  value: 'both'
                },
              ]}
              handleChange={(e: any) => {
                setIntervalType(e.value);
              }}
            />
            {
              (intervalType === 'hour' || intervalType === 'both') &&
              <Input
                name='hourInterval'
                label='Hour Interval'
                placeholder='Hour Interval'
                required
                errors={errors}
                register={register}
              />
            }
            {
              (intervalType === 'date' || intervalType === 'both') &&
              <Input
                name='dateInterval'
                label='Date Interval'
                placeholder='Date Interval'
                required
                errors={errors}
                register={register}
              />
            }
            {
              intervalType === 'cycle' &&
              <Input
                name='cycleInterval'
                label='Cycle Interval'
                placeholder='Cycle Interval'
                required
                errors={errors}
                register={register}
              />
            }
            <Input
              name='ata'
              label='ATA'
              placeholder='ATA'
              required
              errors={errors}
              register={register}
            />
            {/*  {
              (intervalType === 'date' || intervalType === 'both' || intervalType === 'oc') &&
              <Col xs={24} sm={12} lg={6}>
                <Form.Item
                  name="cwDate"
                  label="CW date"

                >
                  <DatePicker />
                </Form.Item>
              </Col>
            } */}
            {
              (intervalType === 'hour' || intervalType === 'both' || intervalType === 'oc') &&
              <Input
                name='cwHour'
                label='CW Hour'
                placeholder='CW Hour'
                required
                errors={errors}
                register={register}
              />
            }
            {
              intervalType === 'cycle' &&
              <Input
                name='cwCycle'
                label='CW Cycle'
                placeholder='CW Cycle'
                required
                errors={errors}
                register={register}
              />
            }
            <Select
              name='appliesTo'
              label='Applies To'
              required
              errors={errors}
              control={control}
              options={
                aircraftElement.status?.map((item: any) =>
                ({
                  label: item.itemName,
                  value: item.itemId
                })
                )
              }
            />
          </div>
        </Modal>
      </div>
    </SubLayout>
  )
}

export default DueList
