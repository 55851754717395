import {
  Route,
  Redirect
} from 'react-router-dom';

function PrivateRoute({ children, isAuthenticated, ...rest }: any) {

  return (
    <Route
      {...rest}
      render={
        () => (
          isAuthenticated
            ?
            children
            :
            <Redirect to='/login'

            />
        )
      }
    />
  );
}

export default PrivateRoute;
