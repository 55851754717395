import { Empty } from 'antd'
import styles from '../styles/VerticalTable.module.scss'

type VerticalTableProps = {
  data?: any[],
  columns?: any
}

export const VerticalTable = ({ data, columns }: VerticalTableProps) => {

  return (
    <div className={styles.verticalTableContainer}>
      {
        (data && data.length > 0) ? data.map((item: any) =>
        (
          <div key={item.id} className={styles.verticalTable}>
            {
              columns.map((header: any) => (
                <div
                  key={header.title}
                  className={styles.verticalTableRow}>
                  <div className={styles.verticalTableHeader}>
                    <span>{header.title}</span></div>
                  {
                    ('render' in header) ?
                      <div className={styles.verticalTableData}>
                        {header.render(item[header.dataIndex], item)}
                      </div>
                      :
                      <div className={styles.verticalTableData}>
                        {item[header.dataIndex]}
                      </div>
                  }
                </div>
              ))
            }
          </div>
        )
        ) : <Empty />
      }
    </div>
  )


}
