import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import Card from '../../components/Card'
import { AuthContext } from '../../context/AuthProvider'
import styles from '../../styles/Home.module.scss'

const FBO = () => {

  const { user } = useContext(AuthContext)

  const userAccess = user.access.map(view => view.view)

  const links = [
    {
      name: 'Fleet',
      description: 'Manage your fleet',
      icon: <span className="material-symbols-rounded">
        construction
      </span>,
      path: '/fbo/fleet',
      generalPath: '/fbo/fleet'
    },
    {
      name: 'Flights',
      description: 'Create and view upcoming flights',
      icon: <span className="material-symbols-rounded">
        flight_takeoff
      </span>,
      path: '/fbo/flights',
      generalPath: '/fbo/flights'
    },
  ]

  return (
    <div className={styles.home}>

      <div className="header">
        <Link to='/' className='btn btn-link btn-icon'>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
          </svg>
          Back to home
        </Link>
      </div>
      <div className={styles.header}>
        <h2>Global FBO</h2>
      </div>
      <div className={styles.grid}>
        {
          user.role.value === 'admin' ? links.map(link => (
            <Card key={link.path} {...link} />
          )) : links.filter(link => userAccess.includes(link.generalPath)).map(link => (
            <Card key={link.path} {...link} />
          ))
        }
      </div>
    </div>
  )
}

export default FBO