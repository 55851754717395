import { FC, useContext } from 'react'
import { AuthContext } from '../context/AuthProvider'

interface Props {
  role: 'view' | 'create-edit' | 'delete'
  view: string,
  children: JSX.Element
}

const PermissionWrapper: FC<Props> = ({ role, view, children }) => {

  const { user } = useContext(AuthContext)

  const renderComponent = user.access.find(userView => userView.view === view)?.permissions.includes(role || '') || user.role.value==='admin'

  return (
    <>
      {renderComponent && children}
    </>
  )
}

export default PermissionWrapper