import { FC, useContext, useState } from 'react'
import styles from '../styles/Layout.module.scss'
import { AuthContext } from '../context/AuthProvider';
import { User } from '../interfaces/User';
import { Helmet } from 'react-helmet'

interface Props {
  children: JSX.Element,
  title?: string
}

const Layout: FC<Props> = ({ children, title='' }) => {

  const { user, setUser } = useContext(AuthContext)

  const [visible, setVisible] = useState(false)

  const renderName = () => {
    const splitted = user.name.split(' ')

    const first = splitted[0].split('')[0]
    let second

    if (splitted.length === 1) {
      second = ''
    }
    if (splitted.length === 2) {
      second = splitted[1].split('')[0]
    }
    if (splitted.length === 3) {
      second = splitted[1].split('')[0]
    }
    if (splitted.length === 4) {
      second = splitted[2].split('')[0]
    }
    if (splitted.length >= 5) {
      second = splitted[3].split('')[0]
    }
    return `${first}${second}`.toUpperCase()
  }

  return (
    <div className='layout'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title} | Global Tracking</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      {/* <Sidebar /> */}
      <div className={styles.topbar}>
        <div className="menuWrapper">
          <div onClick={() => {
            setVisible(!visible)
          }} className="menu">
            <div className={styles.account}>
              <span>{renderName()}</span>
            </div>

          </div>
          {
            visible &&
            <div className="menuOverlay">
              <span
                onClick={() => {
                  setVisible(false)
                  localStorage.removeItem('token')
                  setUser({} as User)
                }}
              > Log out</span>
            </div>
          }
        </div>

      </div>
      <div className={styles.mainContent}>
        {
          children
        }
      </div>
    </div>
  )
}

export default Layout
