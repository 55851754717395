// @ts-ignore
import { motion } from "framer-motion/dist/framer-motion";
import { FC } from 'react'
import styles from '../styles/Drawer.module.scss'

interface Props {
  visible: boolean
  onClose: () => void,
  children: JSX.Element
}

export const Drawer: FC<Props> = ({ visible, onClose, children }) => {
  return (
    <>
      {visible ?
        <div className={styles.drawerWrapper}>
          <motion.div
            initial={{
              opacity: 0
            }}
            animate={{
              opacity: 1
            }}
            exit={{
              opacity: 0
            }}
            onClick={onClose}
            transition={{ delay: 0.2 }}
            className={styles.menuBackdrop}>
          </motion.div>
          <motion.div
            initial={{
              left: '-100%'
            }}
            animate={{
              left: 0,
            }}
            exit={{
              left: '-100%'
            }}
            transition={{ duration: 0.2, type: "tween" }}
            className={styles.menu}>
            <div className={styles.close}>
              <div onClick={onClose} className={styles.icon}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </div>
            </div>
            {children}
          </motion.div>
        </div>
        : null}
    </ >
  )
}


