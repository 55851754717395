import React, { forwardRef } from 'react'
import { useForm } from 'react-hook-form';


interface Props {
  children?: any,
  defaultValues?: any,
  onSubmit?: any,
}

export const Form = forwardRef(({ defaultValues, children, onSubmit }: Props, ref) => {

  const methods = useForm({ defaultValues });

  const { handleSubmit, formState: { errors } } = methods;

  return (
    <form ref={ref as any} onSubmit={handleSubmit(onSubmit)}>
      {children.map((child: any) => {
        return child.props.name
          ? React.createElement(child.type, {
            ...{
              ...child.props,
              register: methods.register,
              control: methods.control,
              key: child.props.name,
              errors
            }
          })
          : React.createElement(child.type, {
            ...{
              ...child.props,
              methods,
              key: child.props.name,
              errors
            }
          });
      })}
    </form>
  );
})