import SubSidebarNavLink from './SubSidebarNavLink'
import styles from '../styles/Subsidebar.module.scss'
import { menuLinks } from '../helpers/helpers'
import { useHistory, useParams } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { AircraftContext } from '../context/AircraftProvider'
import { api } from '../api/api'
import { Popover } from 'antd'
import { Status } from '../interfaces/Aircraft'
import { AuthContext } from '../context/AuthProvider'
import { useAppPrefix } from '../hooks/useAppPrefix'

const Sub = () => {

  const { aircraftId } = useParams<any>()

  const { aircraftElement, setAircraftElement } = useContext(AircraftContext)

  const [loading, setLoading] = useState(true)

  const { user } = useContext(AuthContext)

  const userAccess = user.access.map(view => view.view)

  const { location: { pathname } } = useHistory()

  const appPrefix = useAppPrefix(pathname)

  useEffect(() => {
    const fetchData = async () => {
      if (!aircraftElement.id) {
        try {
          const { data } = await api.get(`/api/aircraft/${aircraftId}`)
          setAircraftElement(data.aircraft)
          setLoading(false)

        } catch (error) {
          setLoading(false)
        }
      } else {
        setLoading(false)
      }
    }
    fetchData()
  }, [aircraftId, setAircraftElement, aircraftElement])

  if (loading) return <div></div>

  return (
    <>
      <div
        className={styles.subSidebar}>
        <div className={styles.aircraftInfo}>
          <div className={styles.aircraftInfoImg}>
            {
              aircraftElement.image &&
              <img alt="Aircraft" src={aircraftElement.image} />
            }
          </div>
          <div className={styles.aircraftInfoData}>
            <div className={styles.groupItem}>
              <span className={styles.item}>Reg #</span>
              <span >{aircraftElement.registrationNumber}</span>
            </div>
            <div className={styles.groupItem}>
              <span className={styles.item}>Serial</span>
              <span>{aircraftElement.serialNumber}</span>
            </div>
            <div className={styles.groupItem}>
              <span className={styles.item}>Model</span>
              <span >{aircraftElement.model}</span>
            </div>
            <div className={styles.groupItem}>
              <span className={styles.item}>Analyst</span>
              <span >{aircraftElement.analyst.name}</span>
            </div>
            <div className={styles.groupItem}>
              <span className={styles.item}>Operator</span>
              <span>{aircraftElement.operator.name}</span>
            </div>
          </div>
          <div className={styles.aircraftInfoStatus}>

            <Popover trigger="click" placement="bottom" content={

              <div style={{
                maxHeight: 290,
                overflowY: 'auto'
              }}
                className="data">
                {
                  aircraftElement.status.map((item: Status) => (
                    <div key={item.itemId} className={styles.groupItem}>
                      <span className={styles.item}>{item.itemName}</span>
                      <span>{item.total} hours</span>
                      <span>{item.cycles} cycles</span>
                    </div>
                  ))
                }
              </div>
            } title="Aircraft Status">
              <button className='btn btn-blue btn-block'>Show times</button>
            </Popover>
          </div>

        </div >
        {<div className={styles.links}>
          <SubSidebarNavLink
            path={`/${appPrefix}/fleet/`}
            icon={<span className="material-symbols-rounded">
              flight
            </span>}
            /*  path={`/${appPrefix}/aircraft/${aircraftId}/${link.path}`}
             icon={link.icon} */
            name='Fleet'
          />
          {
            user.role.value === 'admin' ? menuLinks.map((link) =>
              <SubSidebarNavLink
                key={link.name}
                path={`/${appPrefix}/aircraft/${aircraftId}/${link.path}`}
                icon={link.icon}
                name={link.name}
              />
            ) :
              menuLinks.filter(link => userAccess.includes(link.generalPath)).map((link) =>
                <SubSidebarNavLink
                  key={link.name}
                  path={`/${appPrefix}/aircraft/${aircraftId}/${link.path}`}
                  icon={link.icon}
                  name={link.name}
                />
              )
          }
        </div>}
      </div >
    </>
  )
}

export default Sub
